import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
/// import { LoggedInUserService } from '../loggedInUser/logged-in-user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { userInfo } from 'os';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  url = environment.apiUrl + 'usuario/';
  httpOptions = null;
  userUpdated: Subject<any>;
  newUser: Subject<any>;
  changeFilter: Subject<any>;

  userRefreshUpdated$: BehaviorSubject<boolean>;

  constructor(private httpClient: HttpClient) {

    this.userUpdated = new Subject<any>();
    this.newUser = new Subject<any>();
    this.changeFilter = new Subject<any>();

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };


  }

  getAllUsers(): Observable<any> {
    return this.httpClient.get<any>(this.url, this.httpOptions);
  }

  getAllClients(): Observable<any> {
    return null;

  }

  getUser(user): Observable<any> {
    if (typeof (user) === 'string') {
      return this.httpClient.get<any>(this.url + user, this.httpOptions);
    }
    else {
      return this.httpClient.get<any>(this.url + user.id, this.httpOptions);
    }

  }

  createUser(user): Observable<any> {
    return this.httpClient.post(this.url, user, this.httpOptions);
  }

  editUser(data): Observable<any> {
    return this.httpClient.put(this.url + data.id, data, this.httpOptions);
  }

  deleteUser(data): Observable<any> {
    if (typeof (data) === 'string') {
      return this.httpClient.delete(this.url + data, this.httpOptions);
    }
    else {
      return this.httpClient.delete(this.url + data.id, this.httpOptions);
    }
  }

  // emitUsersUpdated(value: any[]) {
  //   this.allUsers = value;
  //   this.usersUpdated$.next(value);
  // }

  // emitUserUpdated(value: any): any {
  //   this.userUpdated$.next(value);
  // }

  // emitRouteChange() {
  //   this.routeChange$.next(true);
  // }

  // setLastRoute(route: string) {
  //   this.lastRoute = route;
  // }

  // getLastRoute(): string {
  //   return this.lastRoute;
  // }

  // addUser(user: any): Observable<any> {
  //   // TODO: Required for the InMemoryDb to work.
  //   return this.httpClient
  //     .post<any>(
  //       this.url,
  //       user);
  // }

  // editUser(data): Observable<any> {
  //   return this.httpClient
  //     .put<any>(this.urlId.replace(':id', data.idUsuario), data, this.httpOptions);
  // }

  // removeUser(data): Observable<any> {
  //   return this.httpClient
  //     .delete<any>(this.urlId.replace(':id', data.idUsuario), this.httpOptions);
  // }

  // getAllUsers(): Observable<any> {
  //   return this.httpClient
  //     .get<any>(this.url, this.httpOptions);
  // }

  // getUser(data): Observable<any> {
  //   return this.httpClient
  //     .get<any>(this.urlId.replace(':id', data.idUsuario), this.httpOptions);
  // }

  // getAllClients(): Observable<any> {
  //   return this.httpClient
  //     .get<any>(this.clientUrl, this.httpOptions);
  // }
}
