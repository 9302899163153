
import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import {Observable} from 'rxjs';

import {AuthenticationService} from '../../core/authentication/authentication.service';
import {Router} from '@angular/router';
import 'rxjs-compat/add/operator/do';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService,
              private router: Router,
              private toastr: ToastrService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userLocal = this.authenticationService.getUser();

    if (userLocal) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${userLocal.token}`
        }
      });

      return next.handle(request).pipe(
        tap(succ => {
          }, err => {
            if (err.status === 401) {
              this.toastr.error('Your session has expired for security reasons, please log in again.', 'Error!', {
                timeOut: 5000,
                progressBar: true,
                positionClass: 'toast-bottom-right'
              });
              this.authenticationService.logout();
              this.router.navigateByUrl('/auth/login');
            }
          }
        ));
    } else {
      request = request.clone({
        setHeaders: {}
      });

      return next.handle(request);
    }
  }
}
