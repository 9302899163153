import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, EventEmitter, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../i18n/en';
import { locale as spanish } from '../../i18n/es';
import { DepartmentService } from '../../../../backend/department/services/department.service';
import * as moment from 'moment';


import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  AbstractControl
} from '@angular/forms';

@Component({
  selector: 'app-horario-alterno',
  templateUrl: './horario-alterno.component.html',
  styleUrls: ['./horario-alterno.component.scss'],
  encapsulation: ViewEncapsulation.None,


})
export class HorarioAlternoComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  @Input() dataInput;
  @Output() horarioAlternoChange = new EventEmitter<any>();
  formHorario: UntypedFormGroup;
  showEntradas = false;
  listadoHorarios: any[] = [];
  listadoHorarios2Send: any[] = [];
  dataReady = false;
  dayHora = moment();

  constructor(private translate: TranslateService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private fb: UntypedFormBuilder,
    private departmentService: DepartmentService) {
    this._unsubscribeAll = new Subject();
    this._fuseTranslationLoaderService.loadTranslations(english, spanish);

  }

  ngOnInit(): void {
    this.builtForm();
    this.formHorario.valueChanges.subscribe(data => {
      if ((data['trabajoHoras'] !== null && data['descansoHoras'] !== null && data['horaEntrada'] !== null && data['fecha'] !== null)) {
        this.GenerateHorarios();
        this.showEntradas = true;
        this.horarioAlternoChange.emit(this.PrepareDatatosend());
      }
      else {
        this.showEntradas = false;
        this.listadoHorarios = [];
      }

    });

  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  builtForm(): void {
    // console.log(this.dataInput);
    if (this.dataInput === null || this.dataInput === undefined) {
      this.formHorario = this.fb.group({
        trabajoHoras: [null, [Validators.required]],
        descansoHoras: [null, [Validators.required]],
        horaEntrada: [null, [Validators.required]],
        fecha: [this.dayHora, [Validators.required]]
      });
    }
    else {
      this.formHorario = this.fb.group({
        trabajoHoras: [this.dataInput.horas_trabajo, [Validators.required]],
        descansoHoras: [this.dataInput.horas_descanso, [Validators.required]],
        horaEntrada: [this.dataInput.entrada_inicial, [Validators.required]],
        fecha: [this.dayHora, [Validators.required]]
      });
      this.listadoHorarios2Send = this.dataInput.horas;
      this.formHorario.controls['horaEntrada'].setValue(this.dataInput.entrada_inicial);
    }
    this.dataReady = true;

  }

  gcd(a: number, b: number): number {
    return (!(a % b)) ? a : this.gcd(b, (a % b));
  }

  GenerateHorarios(): void {

    const a = this.formHorario.controls['fecha'].value.format('YYYY-MM-DD').toString();
    const b = this.formHorario.controls['horaEntrada'].value.toUpperCase();
    let momentInicial = moment.utc(a + ' ' + b, 'YYYY-MM-DD hh:mm a');
    const momentInicialOne = moment.utc(a + ' ' + b, 'YYYY-MM-DD hh:mm a');
    this.listadoHorarios = [];
    this.listadoHorarios2Send = [];
    const t = this.formHorario.controls['trabajoHoras'].value;
    const d = this.formHorario.controls['descansoHoras'].value;

    ///////////////////////////////////////////////////////

    for (let i = 0; i < 12; i++) {
      const temp = { 'entrada': null, 'salida': null };
      const temp2 = { 'entrada': null, 'salida': null };
      let final = momentInicial;
      temp.entrada = final.format('hh:mm a DD-MM-YYYY').toString();
      temp2.entrada = final.toISOString();
      final = momentInicial.add(t, 'hours');
      temp.salida = final.format('hh:mm a DD-MM-YYYY').toString();
      temp2.salida = final.toISOString();
      final = momentInicial.add(d, 'hours');
      this.listadoHorarios.push(temp);
      this.listadoHorarios2Send.push(temp2);
      momentInicial = final;
      if (final.format('hh:mm a') === momentInicialOne.format('hh:mm a')) {
        this.listadoHorarios.push({ 'entrada': final.format('hh:mm a DD-MM-YYYY').toString(), 'salida': final.add(t, 'hours').format('hh:mm a DD-MM-YYYY').toString() });
        this.listadoHorarios2Send.push({ 'entrada': final.toISOString(), 'salida': final.add(t, 'hours').toISOString() });
        break;
      }
    }
    // console.log(this.listadoHorarios2Send);
  }

  PrepareDatatosend(): any {
    const temp = {};
    temp['horas_trabajo'] = this.formHorario.controls['trabajoHoras'].value;
    temp['horas_descanso'] = this.formHorario.controls['descansoHoras'].value;
    temp['entrada_inicial'] = this.formHorario.controls['horaEntrada'].value;
    temp['Horas'] = this.listadoHorarios2Send;
    return temp;
  }


}
