<div *ngIf="showData" class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span *ngIf="isEditing" class="title dialog-title">{{ 'User Edit' | translate }}</span>
      <span *ngIf="!isEditing" class="title dialog-title">{{ 'Create new user' | translate }}</span>
      <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>

    <mat-toolbar-row *ngIf="user.persona" class="toolbar-bottom py-16" fxLayout="column" fxLayoutAlign="center center">
      <img *ngIf="!hasChangeImage && user.persona.image === null" src="assets/images/avatars/profile.jpg" class="avatar contact-avatar huge m-0"
        (click)="onChangeImage()" />
      <img *ngIf="!hasChangeImage && user.persona.image && user.persona.image != null" src="{{ urlImage + user.persona.image }}?d={{clockTick}}"
        class="avatar contact-avatar huge m-0" (click)="onChangeImage()" />
      <img *ngIf="hasChangeImage" [src]="user.persona.image" class="avatar contact-avatar huge m-0" (click)="onChangeImage()" />
      <div *ngIf="isEditing && user.persona != null " class="contact-name mt-8">{{user.persona.nombre}}
        {{user.persona.apellidos}}</div>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>

    <form [formGroup]="form">

      <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field fxFlex>
          <mat-icon matPrefix class="mr-12 s-20 secondary-text">star</mat-icon>
          <input name="userNick" [formControl]="form.controls['username']" placeholder="{{ 'Username' | translate }}"
            matInput required>
          <mat-error *ngIf="form.controls['username'].hasError('required')">
            {{ 'Field required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field fxFlex="100" class="example-full-width">
          <input type="text" placeholder="{{ 'Person' | translate }}" aria-label="Assignee" matInput [formControl]="form.controls['persona']"
            [matAutocomplete]="auto" required>
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              <img style="width:40px; border-radius:50%;" [src]="urlImage + option.image">{{option.nombre + ' '+
              option.apellidos}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field fxFlex>
          <mat-icon matPrefix class="mr-12 s-20 secondary-text">account_circle</mat-icon>
          <input name="nombre" autofocus [formControl]="form.controls['nombre']" placeholder="{{ 'Name' | translate }}"
            matInput required>
          <mat-error *ngIf="form.controls['nombre'].hasError('required')">
            {{ 'Field required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field fxFlex>
          <mat-icon matPrefix class="mr-12 s-20 secondary-text">account_circle</mat-icon>
          <input name="apellidos" [formControl]="form.controls['apellidos']" placeholder="{{ 'Last Name' | translate }}"
            matInput>
          <!--<mat-error *ngIf="form.controls['apellidos'].hasError('required')">-->
          <!--{{ 'Field required' | translate }}-->
          <!--</mat-error>-->
        </mat-form-field>
      </div>

      <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field fxFlex>
          <mat-icon matPrefix class="mr-12 s-20 secondary-text">email</mat-icon>
          <input name="mail" [formControl]="form.controls['email']" placeholder="{{ 'Email' | translate }}" matInput
            required>
          <mat-error *ngIf="form.controls['email'].hasError('required')">
            {{ 'Field required' | translate }}
          </mat-error>
          <mat-error *ngIf="form.controls['email'].hasError('mail')">
            {{ 'Email is not valid' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field fxFlex>
          <mat-icon matPrefix class="mr-12 s-20 secondary-text">assignment_ind</mat-icon>
          <input name="identificacion" [formControl]="form.controls['identidad']" placeholder="{{ 'DNI' | translate }}"
            matInput required>
          <mat-error *ngIf="form.controls['identidad'].hasError('required')">
            {{ 'Field required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field fxFlex>
          <mat-icon matPrefix class="mr-12 s-20 secondary-text">cake</mat-icon>
          <input matInput [matDatepicker]="picker" [formControl]="form.controls['fecha_nacimiento']" required
            placeholder="{{ 'Birthday' | translate }}">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="form.controls['fecha_nacimiento'].hasError('required')">
            {{ 'Field required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="!isEditing" class="mb-24" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field fxFlex="48">
          <mat-icon matPrefix class="mr-12 s-20 secondary-text">enhanced_encryption</mat-icon>
          <input name="pass" type="password" [formControl]="form.controls['password']" [required]="form.controls['confirmPassword'].value !== ''"
            placeholder="{{ 'Password' | translate }}" matInput required>
          <mat-error *ngIf="form.controls['password'].hasError('required')">
            {{ 'Field required' | translate }}
          </mat-error>
          <mat-error *ngIf="form.controls['password'].invalid">
            {{ errorMessagesService.getSingleErrorMessage(form.controls['password']) }}
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="48">
          <mat-icon matPrefix class="mr-12 s-20 secondary-text">enhanced_encryption</mat-icon>
          <input name="pass" type="password" [formControl]="form.controls['confirmPassword']" [required]="form.controls['password'].value !== ''"
            placeholder="{{ 'Confirm Password' | translate }}" matInput required>
          <mat-error *ngIf="form.controls['confirmPassword'].hasError('required')">
            {{ 'Field required' | translate }}
          </mat-error>
          <mat-error *ngIf="form.controls['confirmPassword'].invalid">
            {{ errorMessagesService.getSingleErrorMessage(form.controls['confirmPassword']) }}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="isEditing" class="mb-24" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field fxFlex="48">
          <mat-icon matPrefix class="mr-12 s-20 secondary-text">enhanced_encryption</mat-icon>
          <input name="pass" type="password" [formControl]="form.controls['password']" [required]="form.controls['confirmPassword'].value !== ''"
            placeholder="{{ 'Password' | translate }}" matInput>
          <mat-error *ngIf="form.controls['password'].invalid">
            {{ errorMessagesService.getSingleErrorMessage(form.controls['password']) }}
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="48">
          <mat-icon matPrefix class="mr-12 s-20 secondary-text">enhanced_encryption</mat-icon>
          <input name="pass" type="password" [formControl]="form.controls['confirmPassword']" [required]="form.controls['password'].value !== ''"
            placeholder="{{ 'Confirm Password' | translate }}" matInput>
          <mat-error *ngIf="form.controls['confirmPassword'].invalid">
            {{ errorMessagesService.getSingleErrorMessage(form.controls['confirmPassword']) }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" class="textarea-wrapper" fxLayoutAlign="start start">
        <mat-form-field fxFlex>
          <textarea name="descripcion" [formControl]="form.controls['descripcion']" placeholder="{{ 'Description' | translate }}"
            matInput max-rows="4"></textarea>
        </mat-form-field>
      </div>

    </form>

  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

    <button *ngIf="!isEditing" mat-raised-button (click)="onEdit()" class="save-button mat-accent" [disabled]="form.invalid || isSaving"
      aria-label="CREATE USER">
      <mat-icon *ngIf="isSaving" class="spin">refresh</mat-icon>
      CREATE USER
    </button>

    <button *ngIf="isEditing" mat-raised-button (click)="onEdit()" class="save-button mat-accent" [disabled]="form.invalid"
      aria-label="SAVE">
      <mat-icon *ngIf="isSaving" class="spin">refresh</mat-icon>
      SAVE
    </button>

    <button *ngIf="isEditing" mat-icon-button (click)="deleteSelectedElements()" aria-label="Delete" matTooltip="Delete">
      <mat-icon>delete</mat-icon>
    </button>

  </div>
</div>
