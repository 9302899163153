import { Component, Inject, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { locale as english } from './../../../backend/department/i18n/en';
import { locale as spanish } from './../../../backend/department/i18n/es';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { FuseProgressBarService } from '../../../../@fuse/components/progress-bar/progress-bar.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ImageCropperComponent } from 'ngx-image-cropper';
@Component({
  selector: 'app-dialog-change-image',
  templateUrl: './dialog-change-image.component.html',
  styleUrls: ['./dialog-change-image.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogChangeImageComponent implements OnInit {
  hideBtn: Boolean = false;
  loadImage = false;
  showErrorImage = false;
  base64textString = null;
  carImage = null;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = true;
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;



  constructor(public dialogRef: MatDialogRef<DialogChangeImageComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private fuseProgressBarService: FuseProgressBarService) {
    this._fuseTranslationLoaderService.loadTranslations(english, spanish);

    if (_data.hideBtn) {
      this.hideBtn = _data.hideBtn;
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  onSave(): void {
    this.dialogRef.close(this.croppedImage);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Upload Image
  // -----------------------------------------------------------------------------------------------------


  // handleReaderLoaded(readerEvt): void {
  //   const binaryString = readerEvt.target.result;
  //   this.base64textString = 'data:image/jpeg;base64,' + btoa(binaryString);
  //   //console.log(this.base64textString);
  //   this.loadImage = true;
  //   this.showErrorImage = false;

  // }

  openFileBrowser(event): void {
    event.preventDefault();
    //console.log(event);

    const element: HTMLElement = document.getElementById(
      'filePicker'
    ) as HTMLElement;
    element.click();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    //console.log(this.croppedImage);
  }

  imageLoaded() {
    this.showCropper = true;
    this.loadImage = true;
    console.log('Image loaded')
  }

  cropperReady() {
    console.log('Cropper ready')
  }

  loadImageFailed() {
    console.log('Load failed');
  }

  rotateLeft() {
    this.imageCropper.rotateLeft();
  }

  rotateRight() {
    this.imageCropper.rotateRight();
  }

  flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }

  flipVertical() {
    this.imageCropper.flipVertical();
  }




}
