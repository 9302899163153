<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span *ngIf="!isEditing" class="title dialog-title">{{ 'Create a Job' | translate }}</span>
      <span *ngIf="isEditing" class="title dialog-title">{{ 'Edit a Job' | translate }}</span>
      <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="m-0" fusePerfectScrollbar>
    <mat-horizontal-stepper [linear]="false" #stepper>
      <mat-step [stepControl]="form">
        <form [formGroup]="form">
          <ng-template matStepLabel>Basic Info.</ng-template>
          <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="gappx" fxLayout.xs="column" fxLayoutAlign.xs="start stretch">
            <mat-form-field class="example-full-width" fxFlex="100">
              <input matInput placeholder="{{'Name'|translate}}" min="0" [formControl]="form.controls['titulo']"
                required>
              <mat-error *ngIf="form.controls['titulo'].hasError('required')"> this field is<strong>
                  required</strong></mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" class="cf-margin-top-10" fxLayoutAlign="start stretch" fxLayoutGap="gappx" fxLayout.xs="column"
            fxLayoutAlign.xs="start stretch">
            <mat-form-field class="example-full-width" fxFlex="100">
              <input matInput type="number" placeholder="{{'Salary'|translate}}" min="0" [formControl]="form.controls['salario']"
                required>
              <mat-error *ngIf="form.controls['salario'].hasError('required')"> this field is<strong>
                  required</strong></mat-error>
              <mat-error *ngIf="form.controls['salario'].hasError('min')"> <strong>out of the range</strong></mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="gappx" fxLayout.xs="row"
            fxLayoutAlign.xs="space-between start">
            <mat-form-field class="example-full-width" fxFlex="45">
              <input matInput type="number" placeholder="{{'Comision Fija'|translate}}" min="0" [formControl]="form.controls['comision_fija']">
            </mat-form-field>

            <section id="section-comision" fxFlex="45" class="example-section">
              <span id="comision">Comision %</span>
              <mat-slide-toggle style="margin-left:10px;" class="example-margin" color="primary" [formControl]="form.controls['comision_porciendo']">
              </mat-slide-toggle>
            </section>
          </div>
          <div style="margin-top:20px;" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="gappx"
            fxLayout.xs="row" fxLayoutAlign.xs="space-between start">
            <section fxFlex="45" class="example-section">
              <span id="comision">Horario Libre:</span>
              <mat-slide-toggle style="margin-left:10px;" class="example-margin" color="primary" [formControl]="form.controls['tiene_horario_libre']">
              </mat-slide-toggle>
            </section>
            <section fxFlex="45" class="example-section">
              <span id="comision">Marcar Asist.:</span>
              <mat-slide-toggle style="margin-left:10px;" class="example-margin" color="primary" [formControl]="form.controls['tiene_reportar_asistencia']">
              </mat-slide-toggle>
            </section>
          </div>
          <div style="margin-top:20px;" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="gappx"
            fxLayout.xs="column" fxLayoutAlign.xs="space-between strech">
            <section fxFlex="30" class="example-section">
              <span id="comision">Hora Entrada:</span>
              <mat-slide-toggle class="example-margin-horaentrada" color="primary" [formControl]="form.controls['tiene_hora_entrada']">
              </mat-slide-toggle>
            </section>
            <section fxFlex="30" class="example-section">
              <span id="comision">Hora Salida:</span>
              <mat-slide-toggle class="example-margin-horasalida" color="primary" [formControl]="form.controls['tiene_hora_salida']">
              </mat-slide-toggle>
            </section>
            <section fxFlex="30" class="example-section">
              <span id="comision">Hora Trabaja:</span>
              <mat-slide-toggle class="example-margin-horatrabaja" color="primary" [formControl]="form.controls['tiene_hora_trabajo']">
              </mat-slide-toggle>
            </section>
          </div>

          <div style="margin-top:50px;" fxLayout="row" class="cf-margin-top-10" fxLayoutAlign="start start" fxLayoutGap="gappx"
            fxLayout.xs="column" fxLayoutAlign.xs="start stretch">
            <mat-form-field fxFlex="100" class="example-full-width">
              <textarea type="text" required matInput [formControl]="form.controls['descripcion']" required placeholder="Descripcion"></textarea>
              <mat-error *ngIf="form.controls['descripcion'].hasError('required')"> this field is<strong>
                  required</strong></mat-error>
            </mat-form-field>
          </div>

        </form>
      </mat-step>
      <!-- horarios hirviendo -->
      <mat-step *ngIf="!form.controls['tiene_horario_libre'].value" [editable]="form.valid">
        <ng-template matStepLabel>Horarios</ng-template>
        <div fxLayout="column" fxLayoutAlign="space-around stretch">
          <div fxLayout="row" style="margin-top:10px;" fxLayoutAlign="space-between start" fxLayoutGap="gappx">
            <mat-radio-button (click)="onChangeRadioButton(Horario)" [checked]="Horario == horario" *ngFor="let Horario of horarios"
              color="primary">{{Horario}}</mat-radio-button>
          </div>
          <div style="margin-top:5px;" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="gappx">
            <mat-form-field fxFlex.xs="25" fxFlex="30" class="example-full-width">
              <input type="number" matInput min="1" placeholder="Repetir cada:" [(ngModel)]="repetir_cada">
            </mat-form-field>
          </div>
          <app-horario-alterno *ngIf="horario =='Alterno' && dataHorariosReady" [dataInput]="dataInputHorarioAlterno"
            (horarioAlternoChange)="OnhorarioAlternoChange($event)"></app-horario-alterno>
          <app-horario-semanal *ngIf="horario =='Semanal' && dataHorariosReady" [dataInput]="dataInputHorarioSemanal"
            (horarioSemanalChange)="OnhorarioSemanalChange($event)"></app-horario-semanal>
          <app-horario-mensual *ngIf="horario =='Mensual' && dataHorariosReady" [dataInput]="dataInputHorarioMensual"
            (horarioMensualChange)="OnhorarioMensualChange($event)"></app-horario-mensual>
        </div>
      </mat-step>

    </mat-horizontal-stepper>


  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end end ">
    <button mat-raised-button (click)="onCreate()" [disabled]="form.invalid || (horario == null && !form.controls['tiene_horario_libre'].value || isSaving )"
      class="save-button mat-accent" aria-label="SAVE">
      {{'Save'|translate}}
    </button>
  </div>

</div>
