import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../i18n/en';
import { locale as spanish } from '../../i18n/es';
import { DepartmentService } from '../../../../backend/department/services/department.service';
import {
  UntypedFormBuilder,
  FormGroup,
  Validators,
  AbstractControl
} from '@angular/forms';


@Component({
  selector: 'app-horario-semanal',
  templateUrl: './horario-semanal.component.html',
  styleUrls: ['./horario-semanal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HorarioSemanalComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  SemanasArray: any[] = [];
  iteradorArray: number[] = [];
  @Input() dataInput = [];
  @Output() horarioSemanalChange = new EventEmitter<any>();
  showData = false;

  constructor(private translate: TranslateService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private fb: UntypedFormBuilder, private departmentService: DepartmentService) { }


  ngOnInit(): void {
    this.builddata();
    this._unsubscribeAll = new Subject();
    this._fuseTranslationLoaderService.loadTranslations(english, spanish);

    this.departmentService.sendHorarioOneWeek.subscribe(data => {
      if (data) {
        console.log(data);
        const index = data.id - 1;
        this.SemanasArray[index] = { 'HorarioDiaSemanas': data.data };
        this.horarioSemanalChange.next(this.SemanasArray);
      }
    });

  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.departmentService.giveNewHorario.unsubscribe();
    // this.departmentService.sendHorarioOneWeek.unsubscribe();
  }

  onAddSemana(): void {
    if (this.iteradorArray.length === 0) {
      this.iteradorArray.push(1);
      this.SemanasArray.push(null);
      this.dataInput.push({ 'horarioDiaSemanas': [] });
    }
    else {
      this.iteradorArray.push(this.iteradorArray[this.iteradorArray.length - 1] + 1);
      this.SemanasArray.push(null);
      this.dataInput.push({ 'horarioDiaSemanas': [] });
    }

  }

  onDeleteSemana(): void {
    if (this.iteradorArray.length > 1) {
      this.iteradorArray.pop();
      this.SemanasArray.pop();
      this.dataInput.pop();
    }

  }

  builddata(): void {
    console.log(this.dataInput);
    console.log("es este");
    if (this.dataInput && this.dataInput.length > 0) {
      for (let i = 0; i < this.dataInput.length; i++) {
        this.iteradorArray.push(i + 1);
        this.SemanasArray.push(null);
      }
    }
    else {
      this.iteradorArray = [];
      this.SemanasArray = [];
      this.dataInput = [];
    }
    this.showData = true;
  }

}
