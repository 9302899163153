import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'applications',
    title: 'Applications',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    children: [
      {
        id: 'department',
        title: 'Department',
        translate: 'NAV.DEPARTMENT.TITLE',
        type: 'item',
        icon: 'meeting_room',
        url: 'backend/department'
        // badge: {
        //   title: '25',
        //   translate: 'NAV.DEPARTMENT.BADGE',
        //   bg: '#F44336',
        //   fg: '#FFFFFF'
        // }
      },
      {
        id: 'cargos',
        title: 'Cargos',
        translate: 'NAV.CARGOS.TITLE',
        type: 'item',
        icon: 'work',
        url: 'backend/cargos'
        // badge: {
        //   title: '25',
        //   translate: 'NAV.DEPARTMENT.BADGE',
        //   bg: '#F44336',
        //   fg: '#FFFFFF'
        // }
      },
      {
        id: 'personal',
        title: 'Personal',
        translate: 'NAV.PERSONAL.TITLE',
        type: 'item',
        icon: 'group',
        url: 'backend/personal'
        // badge: {
        //   title: '25',
        //   translate: 'NAV.DEPARTMENT.BADGE',
        //   bg: '#F44336',
        //   fg: '#FFFFFF'
        // }
      },
      {
        id: 'to-do',
        title: 'To-Do',
        translate: 'NAV.TASK.TITLE',
        type: 'item',
        icon: 'check_box',
        url: 'backend/todo',
        // badge: {
        //   title: '3',
        //   bg: '#FF6F00',
        //   fg: '#FFFFFF'
        // }
      },
      {
        id: 'asistencia',
        title: 'Asistencia',
        translate: 'NAV.ASISTENCIA.TITLE',
        type: 'item',
        icon: 'assignment_turned_in',
        url: 'backend/asistencia'
        // badge: {
        //   title: '25',
        //   translate: 'NAV.DEPARTMENT.BADGE',
        //   bg: '#F44336',
        //   fg: '#FFFFFF'
        // }

      },
      {
        id: 'users',
        title: 'Users',
        translate: 'NAV.USERS',
        type: 'item',
        icon: 'account_box',
        url: '/backend/users'
      },
    ]
  }
];
