import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, EventEmitter, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../i18n/en';
import { locale as spanish } from '../../i18n/es';
import { DepartmentService } from '../../../../backend/department/services/department.service';
import { SelectionModel } from '@angular/cdk/collections';
import * as moment from 'moment';
import {
  UntypedFormBuilder,
  FormGroup,
  Validators,
  AbstractControl
} from '@angular/forms';


@Component({
  selector: 'app-horario-mensual',
  templateUrl: './horario-mensual.component.html',
  styleUrls: ['./horario-mensual.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HorarioMensualComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  listHorarioType1: number[] = [];
  listHorarioType2: number[] = [];
  HorarioMensualType1: any[] = [];
  HorarioMensualType2: any[] = [];
  checklistSelectionType1 = new SelectionModel<number>(true);
  checklistSelectionType2 = new SelectionModel<number>(true);
  @Output() horarioMensualChange = new EventEmitter<any>();
  @Input() dataInput: any;
  showData = false;

  constructor(private translate: TranslateService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private fb: UntypedFormBuilder, private departmentService: DepartmentService) {
    this._unsubscribeAll = new Subject();
    this._fuseTranslationLoaderService.loadTranslations(english, spanish);
    ///////////////////////
    this.departmentService.sendHorarioOneMesType1.subscribe(data => {
      let found = false;
      for (let i = 0; i < this.HorarioMensualType1.length; i++) {
        if (data.id === this.HorarioMensualType1[i].id) {
          this.HorarioMensualType1[i] = { 'id': data.id, 'dia_mes': data.dia, 'Horas': [{ 'entrada': data.entrada, 'salida': data.salida }] };
          found = true;
          break;
        }
      }
      if (!found) {
        this.HorarioMensualType1.push({
          'id': data.id, 'dia_mes': data.dia,
          'Horas': [{ 'entrada': data.salida, 'salida': data.entrada }]
        });
      }
      this.horarioMensualChange.next(this.ProcessData2Send());
      // console.log(this.HorarioMensualType1);
    });
    this.departmentService.sendHorarioOneMesType2.subscribe(data => {
      let found = false;
      for (let i = 0; i < this.HorarioMensualType2.length; i++) {
        if (data.id === this.HorarioMensualType2[i].id) {
          this.HorarioMensualType2[i] = {
            'id': data.id, 'nro_semana': data.indexMes,
            'dia_semana': data.dia, 'Horas': [{ 'entrada': data.entrada, 'salida': data.salida }]
          };
          found = true;
          break;
        }
      }
      if (!found) {
        this.HorarioMensualType2.push({
          'id': data.id, 'nro_semana': data.indexMes,
          'dia_semana': data.dia, 'Horas': [{ 'entrada': data.entrada, 'salida': data.salida }]
        });
      }
      // console.log(this.HorarioMensualType2);
      this.horarioMensualChange.next(this.ProcessData2Send());
    });
  }

  ngOnInit(): void {
    // console.log(this.dataInput);


    this.showData = true;


  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onAddType1(): void {
    if (this.listHorarioType1.length === 0) {
      this.listHorarioType1.push(1);
    }
    else {
      this.listHorarioType1.push(this.listHorarioType1[this.listHorarioType1.length - 1] + 1);
    }

  }

  onAddType2(): void {
    if (this.HorarioMensualType2.length === 0) {
      this.listHorarioType2.push(1);
    }
    else {
      this.listHorarioType2.push(this.listHorarioType2[this.listHorarioType2.length - 1] + 1);
    }

  }

  onDeleteType1(): void {
    if (this.listHorarioType1.length > 1) {
      this.listHorarioType1.pop();
      this.HorarioMensualType1.pop();
    }

  }

  onDeleteType2(): void {
    if (this.listHorarioType2.length > 1) {
      this.listHorarioType2.pop();
      this.HorarioMensualType2.pop();
    }
  }

  oncheckHorarioType1(id1: number): void {
    if (this.checklistSelectionType1.isSelected(id1)) {
      this.checklistSelectionType1.deselect(id1);
    }
    else {
      this.checklistSelectionType1.select(id1);
      //this.checklistSelectionType2.clear();
    }
    this.horarioMensualChange.next(this.ProcessData2Send());
  }

  oncheckHorarioType2(id2: number): void {
    if (this.checklistSelectionType2.isSelected(id2)) {
      this.checklistSelectionType2.deselect(id2);
    }
    else {
      this.checklistSelectionType2.select(id2);
      //this.checklistSelectionType1.clear();
    }
    this.horarioMensualChange.next(this.ProcessData2Send());
  }

  ProcessData2Send(): any {
    const temp1: any[] = [];
    const temp2: any[] = [];
    if (!this.checklistSelectionType1.isEmpty()) {
      for (let i = 0; i < this.HorarioMensualType1.length; i++) {
        if (this.checklistSelectionType1.isSelected(this.HorarioMensualType1[i].id)) {
          const dato = JSON.parse(JSON.stringify(this.HorarioMensualType1[i]));
          delete dato.id;
          temp1.push(dato);
        }
      }
      // console.log(temp1);
    } if (!this.checklistSelectionType2.isEmpty()) {
      for (let i = 0; i < this.HorarioMensualType2.length; i++) {
        if (this.checklistSelectionType2.isSelected(this.HorarioMensualType2[i].id)) {
          const dato = JSON.parse(JSON.stringify(this.HorarioMensualType2[i]));
          delete dato.id;
          temp2.push(dato);
        }
      }
    }
    return { 'HorarioDiaMes': temp1, 'HorarioSemanaMes': temp2 };
  }

}
