export const locale = {
  lang: 'es',
  data: {
    'NAV': {
      'APPLICATIONS': 'Aplicación',
      'DEPARTMENT': {
        'TITLE': 'Departamento',
        'BADGE': '0'
      },
      'CARGOS': {
        'TITLE': 'Cargos',
        'BADGE': '0'
      },
      'PERSONAL': {
        'TITLE': 'Empleados',
        'BADGE': '0'
      },
      'ASISTENCIA': {
        'TITLE': 'Asistencia',
        'BADGE': '0'
      },
      'USERS': 'Usuarios',
      'TASK': {
        'TITLE': 'Tareas',
        'BADGE': '0'
      },
    },
  }
};
