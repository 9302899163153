<div *ngIf="showData" fxLayout="column" fxLayoutAlign="space-around stretch">
  <div class="oneweek" fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="gappx">
    <span id="cabecera"><strong>Semana:{{weeknumber}}</strong></span>
  </div>
  <mat-list-item fxFlex="100" *ngFor="let day of Week;let i = index">
    <div fxLayout="row" fxFlex="100" id="checkboxDayRow" fxLayoutAlign="start start" fxLayoutAlign.xs="start start"
      class="list-item-div-person">
      <mat-checkbox fxFlex="5" (change)="oncheckDay(day)" [checked]="checklistSelection.isSelected(day)">
      </mat-checkbox>
      <span fxFlex="10" *ngIf="checklistSelection.isSelected(day)" id="Day"><strong>{{day+':'}}</strong></span>
      <span fxFlex="10" *ngIf="!checklistSelection.isSelected(day)" id="Day">{{day+':'}}</span>
      <mat-accordion *ngIf="showHorariosSemanales && checklistSelection.isSelected(day)" class="accordionHorarios"
        fxFlex="70" fxFlex.xs="80" displayMode="default" [multi]="true" [hideToggle]="false">
        <mat-expansion-panel [hideToggle]="false">
          <!-- //////////////////////////////////////// -->
          <mat-expansion-panel-header><span id=expansion-panel-header>Horario Entrada-Salida</span></mat-expansion-panel-header>

          <div *ngFor="let interval of horariosSemanales[i]" fxLayout="row" fxLayoutAlign="space-between start"
            fxLayoutGap="gappx" fxLayout.xs="column" fxLayoutAlign.xs="space-around stretch">

            <div fxLayout="row" fxFlex="47" fxLayoutAlign="space-between start" fxLayoutGap="gappx">
              <span id="label-in-out" fxFlex="25"><strong>Entrada:</strong></span>
              <div fxFlex="50">
                <input class="min-time-example" [(ngModel)]="interval.entrada" matInput placeholder="{{'pick'|translate}}"
                  [ngxTimepicker]="min">
                <ngx-material-timepicker #min></ngx-material-timepicker>
              </div>
            </div>

            <div fxLayout="row" fxFlex="47" fxLayoutAlign="space-between start" fxLayoutGap="gappx">
              <span id="label-in-out" fxFlex="25"><strong>Salida:</strong></span>
              <div fxFlex="50">
                <input class="max-time-example" [(ngModel)]="interval.salida" matInput placeholder="{{'pick'|translate}}"
                  [ngxTimepicker]="max">
                <ngx-material-timepicker #max></ngx-material-timepicker>
              </div>
            </div>

          </div>

          <!-- /////////////////////////////////////////////// -->
          <mat-action-row>
            <button mat-button (click)="onAddHorario(day)">
              <mat-icon class="mat-18">add</mat-icon>
            </button>
          </mat-action-row>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-list-item>
  <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="gappx">
    <button mat-button (click)="onSaveChanges()">
      <span><strong>Guardar:</strong></span>
      <mat-icon class="mat-36">check</mat-icon>
    </button>

  </div>



</div>
