import {NgModule} from '@angular/core';

import {
  Routes,
  RouterModule,
  PreloadAllModules
} from '@angular/router';
import {AuthenticationGuard} from './authentication/authentication.guard';
import {BackendGuard} from './backend/backend.guard';

// import {LayoutGuard} from './backend/layout/layout.guard';
// import {AuthenticationGuard} from './authentication/authentication.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'asistencia',
    loadChildren: () => import('./asistencia/asistencia.module').then(m => m.AsistenciaModule)
  },
  {
    path: 'backend',
    loadChildren: () => import('./backend/backend.module').then(m => m.BackendModule),
    canActivate: [BackendGuard]
  },
  {
    path: 'error',
    loadChildren: () => import('./errors/404/error-404.module').then(m => m.Error404Module),
    // canActivate: [LayoutGuard]
  },
  {
    path: '**',
    redirectTo: 'auth/login'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
