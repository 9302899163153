export const locale = {
  lang: 'es',
  data: {
    'Departments': 'Departamentos',
    'Back': 'Atrás',
    'Department Edit': 'Editar Departamento',
    'Department': 'Departamento',
    'Department name is required': 'El nombre del departamento es requerido',
    'Save': 'Guardar',
    'Create Department': 'Crear Departamento',
    'Department Name': 'Nombre del Departamento',
    'Informatica': 'Informática',
    'Basic Info': 'Información Básica',
    'Name': 'Nombre',
    'Maximum Staff': 'Personal Maximo',
    'Minimal Staff': 'Personal Minimo',
    'Create Place': 'Crear Cargo',
    'Place Created': 'Cargo Creado',
    'Place Edited': 'Cargo Editado',
    'Department Details': 'Detalles del Depatamento',
    'Workers': 'Trabajadores'
  }
};
