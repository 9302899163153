import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation, ViewChild, ElementRef
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  AbstractControl
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { buildMapFromSet } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { DepartmentService } from '../../../backend/department/services/department.service';
import { group } from '@angular/animations';
import { Cargo } from '../../../backend/department/clases/cargo.class';


@Component({
  selector: 'app-dialog-add-cargo',
  templateUrl: './dialog-add-cargo.component.html',
  styleUrls: ['./dialog-add-cargo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogAddCargoComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  form: UntypedFormGroup;
  isEditing = false;
  selectedCargo: Cargo;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('TabGroup') Tabgroup: ElementRef;
  horarios = ['Alterno', 'Semanal', 'Mensual', 'Anual'];
  horario = null;
  repetir_cada = 1;
  dataHorariosReady = false;
  isSaving = false;
  //////////////////////////////////////////// Data Horario ////////////////////////////
  dataOutputHorarioAlterno: any = {};
  dataOutputHorarioSemanal: any = {};
  dataOutputHorarioMensual: any = {};
  dataInputHorarioAlterno: any = null;
  dataInputHorarioSemanal: any = null;
  dataInputHorarioMensual: any = null;
  /////////////////////////////////////////////////////////////////////////////

  constructor(public dialogRef: MatDialogRef<DialogAddCargoComponent>,
    @Inject(MAT_DIALOG_DATA) private dataDialog: any,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private departmentService: DepartmentService) {
    this.isEditing = this.dataDialog.isEditing;
    this.selectedCargo = this.dataDialog.selectedCargo;
    this._unsubscribeAll = new Subject();
    // console.log(this.selectedCargo);
  }

  ngOnInit(): void {
    this.buildForm();
    console.log(this.selectedCargo)
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  buildForm(): void {
    if (!this.isEditing) {
      this.form = this.fb.group({
        titulo: [null, [Validators.required]],
        descripcion: [null, [Validators.required]],
        salario: [null, [Validators.required, Validators.min(0.0)]],
        comision_fija: [0.0],
        comision_porciendo: [false, []],
        tiene_horario_libre: [false],
        tiene_reportar_asistencia: [false],
        tiene_hora_salida: [false],
        tiene_hora_entrada: [false],
        tiene_hora_trabajo: [false],
        horarios: [[], []]
      });
      this.dataInputHorarioAlterno = null;
      this.dataInputHorarioMensual = null;
      this.dataInputHorarioSemanal = null;
      this.dataHorariosReady = true;

    }
    else {
      if (this.selectedCargo.horarios && this.selectedCargo.horarios.length > 0) {
        this.form = this.fb.group({
          titulo: [this.selectedCargo.titulo, [Validators.required]],
          descripcion: [this.selectedCargo.descripcion, [Validators.required]],
          salario: [this.selectedCargo.salario, [Validators.required, Validators.min(0.0)]],
          comision_fija: [this.selectedCargo.comision_fija, []],
          comision_porciendo: [this.selectedCargo.comision_porciendo, []],
          tiene_horario_libre: [this.selectedCargo.horarios[0].tiene_horario_libre], /// cambiar cuando se actualice la base de datos
          tiene_reportar_asistencia: [this.selectedCargo.horarios[0].tiene_reportar_asistencia], /// cambiar cuando se actualice la base de datos
          tiene_hora_salida: [this.selectedCargo.horarios[0].tiene_reportar_asistencia], /// cambiar cuando se actualice la base de datos
          tiene_hora_entrada: [this.selectedCargo.horarios[0].tiene_hora_entrada], /// cambiar cuando se actualice la base de datos
          tiene_hora_trabajo: [this.selectedCargo.horarios[0].tiene_hora_trabajo], /// cambiar cuando se actualice la base de datos,
          horarios: [this.selectedCargo.horarios, []]
        });

      }
      else {
        this.form = this.fb.group({
          titulo: [this.selectedCargo.titulo, [Validators.required]],
          descripcion: [this.selectedCargo.descripcion, [Validators.required]],
          salario: [this.selectedCargo.salario, [Validators.required, Validators.min(0.0)]],
          comision_fija: [this.selectedCargo.comision_fija, []],
          comision_porciendo: [this.selectedCargo.comision_porciendo, []],
          tiene_horario_libre: [true], /// cambiar cuando se actualice la base de datos
          tiene_reportar_asistencia: [false], /// cambiar cuando se actualice la base de datos
          tiene_hora_salida: [false], /// cambiar cuando se actualice la base de datos
          tiene_hora_entrada: [false], /// cambiar cuando se actualice la base de datos
          tiene_hora_trabajo: [false], /// cambiar cuando se actualice la base de datos,
          horarios: [this.selectedCargo.horarios, []]
        });

      }

      // console.log(this.selectedCargo);


      if (this.selectedCargo.horarios.length > 0) {

        this.repetir_cada = this.selectedCargo.horarios[0].repetir_cada;
        this.horario = this.selectedCargo.horarios[0].tipo;
        this.horario = this.horario[0].toUpperCase() + this.horario.slice(1);

        switch (this.horario) {
          case 'Alterno':
            this.dataInputHorarioAlterno = JSON.parse(JSON.stringify(this.selectedCargo.horarios[0].horarioAlterno));
            break;
          case 'Semanal':
            this.dataInputHorarioSemanal = JSON.parse(JSON.stringify(this.selectedCargo.horarios[0].horarioSemanal));
            break;
          case 'Mensual':
            this.dataInputHorarioMensual = JSON.parse(JSON.stringify(this.selectedCargo.horarios[0].horarioMensual));
        }

      }
      else {
        this.dataInputHorarioAlterno = null;
        this.dataInputHorarioMensual = null;
        this.dataInputHorarioSemanal = null;
      }
      this.dataHorariosReady = true;
    }

  }

  onCreate(): void {
    const temp = this.form.value;
    let newCargo: any = {};
    newCargo = temp;
    // console.log(newCargo);
    this.isSaving = true;
    if (newCargo.tiene_horario_libre) {
      if (!this.isEditing) {
        newCargo['horarios'] = [];
        this.departmentService.createCargo(newCargo).subscribe(value => {
          this.departmentService.newcargo.emit(value);
          this.toastr.success(
            this.translate.instant('Place Created'),
            'Success!',
            {
              timeOut: 2000,
              progressBar: true,
              positionClass: 'toast-bottom-right'
            }
          );
          this.dialogRef.close(true);
          this.isSaving = false;
        },
          error => {
            const errorMessage = error.error;
            if (Object.keys(errorMessage)[0]) {
              const errorCat = Object.keys(errorMessage)[0];
              this.toastr.error(errorMessage[errorCat][0], 'Error', {
                timeOut: 2000,
                progressBar: true,
                positionClass: 'toast-bottom-right'
              });
            }
            this.isSaving = false;
          }
        );
      }
      else {
        for (const key in newCargo) {
          if (newCargo.hasOwnProperty(key)) {
            this.selectedCargo[key] = newCargo[key];
          }
        }
        newCargo['horarios'] = [];
        this.selectedCargo['horarios'] = [];
        // console.log(this.selectedCargo);
        this.departmentService.editCargo(this.selectedCargo).subscribe(value => {
          this.departmentService.newcargo.emit(value);
          this.toastr.success(
            this.translate.instant('Place Edited'),
            'Success!',
            {
              timeOut: 2000,
              progressBar: true,
              positionClass: 'toast-bottom-right'
            }
          );
          this.isSaving = false;
          this.dialogRef.close(true);
        },
          error => {
            const errorMessage = error.error;
            if (Object.keys(errorMessage)[0]) {
              const errorCat = Object.keys(errorMessage)[0];
              this.toastr.error(errorMessage[errorCat][0], 'Error', {
                timeOut: 2000,
                progressBar: true,
                positionClass: 'toast-bottom-right'
              });
            }
            this.isSaving = false;
          }
        );
      }

    }
    else {
      newCargo['horarios'] = [];
      newCargo.horarios.push(this.ParciarHorario2Send(newCargo));
      if (!this.isEditing) {
        this.departmentService.createCargo(newCargo).subscribe(value => {
          this.departmentService.newcargo.emit(value);
          this.toastr.success(
            this.translate.instant('Place Created'),
            'Success!',
            {
              timeOut: 2000,
              progressBar: true,
              positionClass: 'toast-bottom-right'
            }
          );
          this.dialogRef.close(true);
        },
          error => {
            const errorMessage = error.error;
            if (Object.keys(errorMessage)[0]) {
              const errorCat = Object.keys(errorMessage)[0];
              this.toastr.error(errorMessage[errorCat][0], 'Error', {
                timeOut: 2000,
                progressBar: true,
                positionClass: 'toast-bottom-right'
              });
            }
          }
        );
      }
      else {
        for (const key in newCargo) {
          if (newCargo.hasOwnProperty(key)) {
            this.selectedCargo[key] = newCargo[key];
          }
        }
        // console.log(this.selectedCargo);
        this.departmentService.editCargo(this.selectedCargo).subscribe(value => {
          this.departmentService.newcargo.emit(value);
          // console.log(value);
          this.toastr.success(
            this.translate.instant('Place Edited'),
            'Success!',
            {
              timeOut: 2000,
              progressBar: true,
              positionClass: 'toast-bottom-right'
            }
          );
          this.dialogRef.close(true);

        },
          error => {
            const errorMessage = error.error;
            if (Object.keys(errorMessage)[0]) {
              const errorCat = Object.keys(errorMessage)[0];
              this.toastr.error(errorMessage[errorCat][0], 'Error', {
                timeOut: 2000,
                progressBar: true,
                positionClass: 'toast-bottom-right'
              });
            }
          }
        );
      }


    }

  }
  //////////////////////////////////////////////////////////////////
  onChangeRadioButton(value): void {
    this.horario = value;
  }

  ParciarHorario2Send(newCargo): any {
    const horario = {};
    horario['tiene_horario_libre'] = newCargo['tiene_horario_libre'];
    // delete newCargo.tiene_horario_libre;
    horario['tiene_reportar_asistencia'] = newCargo['tiene_reportar_asistencia'];
    // delete newCargo.tiene_reportar_asistencia;
    horario['tiene_hora_salida'] = newCargo['tiene_hora_salida'];
    // delete newCargo.tiene_hora_salida;
    horario['tiene_hora_entrada'] = newCargo['tiene_hora_entrada'];
    // delete newCargo.tiene_hora_entrada;
    horario['tiene_hora_trabajo'] = newCargo['tiene_hora_trabajo'];
    // delete newCargo.tiene_hora_trabajo;

    if (this.horario === 'Alterno') {
      horario['tipo'] = 'alterno';
      horario['repetir_cada'] = this.repetir_cada;
      horario['HorarioAlterno'] = this.dataOutputHorarioAlterno;
      horario['nombre'] = null;
      horario['descripcion'] = null;
    }
    else if (this.horario === 'Semanal') {
      horario['tipo'] = 'semanal';
      horario['repetir_cada'] = this.repetir_cada;
      horario['HorarioSemanal'] = this.dataOutputHorarioSemanal;
      horario['nombre'] = null;
      horario['descripcion'] = null;

    }
    else if (this.horario === 'Mensual') {
      horario['tipo'] = 'mensual';
      horario['repetir_cada'] = this.repetir_cada;
      horario['HorarioMensual'] = [this.dataOutputHorarioMensual];
      horario['nombre'] = null;
      horario['descripcion'] = null;

    }
    else if (this.horario === 'Anual') {

    }
    return JSON.parse(JSON.stringify(horario));
  }

  ///////////////// DataBinding//////////
  OnhorarioAlternoChange(data): void {
    if (data) {
      this.dataOutputHorarioAlterno = data;
      // console.log(this.dataOutputHorarioAlterno);
    }
  }
  OnhorarioSemanalChange(data): void {
    if (data) {
      this.dataOutputHorarioSemanal = data;
      //console.log(this.dataOutputHorarioSemanal);
      // console.log(this.dataOutputHorarioSemanal);
      // console.log("pepe");
    }
  }

  OnhorarioMensualChange(data): void {
    if (data) {
      this.dataOutputHorarioMensual = data;
      // console.log(this.dataOutputHorarioMensual);
      // console.log("pepe");
    }

  }




  /////////////////////////////////////////////////////////////

}
