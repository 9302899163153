import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, OnChanges, HostListener } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../../i18n/en';
import { locale as spanish } from '../../../i18n/es';
import { DepartmentService } from '../../../../../backend/department/services/department.service';
import * as moment from 'moment';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  AbstractControl,
  FormArray
} from '@angular/forms';


@Component({
  selector: 'app-horario-one-mes-type2',
  templateUrl: './horario-one-mes-type2.component.html',
  styleUrls: ['./horario-one-mes-type2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HorarioOneMesType2Component implements OnInit, OnDestroy {
  @Input() id;
  private _unsubscribeAll: Subject<any>;
  listofDias: any[] = [];
  form: UntypedFormGroup;
  Semana = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
  Indexs = ['1er', '2do', '3cer', '4to'];
  MapNumSemana2number: Map<string, number>;
  MapDia2number: Map<string, number>;
  IterInit = '1er';
  diaInit = 'Lunes';


  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    if (window.innerWidth > 300 && window.innerWidth < 480) {
      // this.Semana = ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'];
      // this.Indexs = ['1er', '2do', '3cer', '4to'];
      // this.IterInit = '1er';
      // this.diaInit = 'Lun';
      // this.form.controls['dia'].setValue(this.MapDia2number.get(this.form.controls['dia'].value));
    }
    else {
      // this.Semana = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
      // this.Indexs = ['primer', 'segundo', 'tercer', 'cuarto'];
      // this.IterInit = 'primer';
      // this.diaInit = 'Lunes';
      // this.form.controls['dia'].setValue(this.MapNumSemana2number.get(this.form.controls['dia'].value));
    }

  }

  constructor(private translate: TranslateService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private fb: UntypedFormBuilder, private departmentService: DepartmentService) {
    this._unsubscribeAll = new Subject();
    this._fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.MapDia2number = new Map<string, number>();
    this.MapNumSemana2number = new Map<string, number>();
  }

  ngOnInit(): void {
    this.buildForm();
    this.form.valueChanges.subscribe(data => {
      const temp: any = {};
      temp['id'] = data.id;
      temp['indexMes'] = data.indexMes;
      temp['dia'] = data.dia;
      temp['entrada'] = moment.utc(data.entrada, 'hh:mm a').toISOString();
      temp['salida'] = moment.utc(data.salida, 'hh:mm a').toISOString();
      this.departmentService.sendHorarioOneMesType2.emit(this.preprocesData2Send(temp));
    });

  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  buildForm(): void {
    this.form = this.fb.group({
      id: [this.id, []],
      indexMes: [this.IterInit, []],
      dia: [this.diaInit, []],
      entrada: ['08:00 am', []],
      salida: ['05:00 pm', []]
    });
    for (let i = 0; i < this.Semana.length; i++) {
      this.MapDia2number.set(this.Semana[i], i + 1);
    }
    for (let i = 0; i < 4; i++) {
      this.MapNumSemana2number.set(this.Indexs[i], i + 1);
    }
    const temp: any = {};
    temp['id'] = this.id;
    temp['indexMes'] = this.IterInit;
    temp['dia'] = this.diaInit;
    temp['entrada'] = moment.utc('08:00 am', 'hh:mm a').toISOString();
    temp['salida'] = moment.utc('05:30 pm', 'hh:mm a').toISOString();

    this.departmentService.sendHorarioOneMesType2.emit(this.preprocesData2Send(temp));

  }

  preprocesData2Send(data: any): any {
    const temp: any = { 'id': null, 'indexMes': null, 'dia': null, 'entrada': null, 'salida': null };
    temp.id = data.id;
    temp.indexMes = this.MapNumSemana2number.get(data.indexMes);
    temp.dia = this.MapDia2number.get(data.dia);
    temp.entrada = data.entrada;
    temp.salida = data.salida;
    // console.log(temp);
    return temp;
  }



}
