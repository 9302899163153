export const locale = {
  lang: 'en',
  data: {
    'Save': 'Save',
    'Name': 'Name',
    'Last Name': 'Last Name',
    'Workers': 'Workers',
    'DNI': 'DNI',
    'City': 'City',
    'Location': 'Location',
    'Address':'Address',
    'Birthdate':'Birthdate',
    'Phone':'Phone',
    'Movil':'Movil',
    'Email':'Email',
  }
};
