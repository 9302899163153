<div fxFlex="100" fxLayout="column" fxLayoutAlign="space-between stretch">

  <div id="cabecera" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
    <h2>Tipo 1</h2>
  </div>
  <mat-checkbox *ngFor="let id1 of listHorarioType1" (change)="oncheckHorarioType1(id1)" [checked]="checklistSelectionType1.isSelected(id1)">
    <app-horario-one-mes-type1 [id]="id1"></app-horario-one-mes-type1>
  </mat-checkbox>
  <div style="margin-top:5px;" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="gappx">
    <button mat-button (click)="onAddType1()">
      <mat-icon class="mat-18">add</mat-icon>
    </button>
    <button mat-button (click)="onDeleteType1()">
      <mat-icon class="mat-18">delete</mat-icon>
    </button>
  </div>
  <div id="cabecera" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
    <h2>Tipo 2</h2>
  </div>
  <mat-checkbox *ngFor="let id2 of listHorarioType2" (change)="oncheckHorarioType2(id2)" [checked]="checklistSelectionType2.isSelected(id2)">
    <app-horario-one-mes-type2 [id]="id2"></app-horario-one-mes-type2>
  </mat-checkbox>
  <div style="margin-top:5px;" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="gappx">
    <button mat-button (click)="onAddType2()">
      <mat-icon class="mat-18">add</mat-icon>
    </button>
    <button mat-button (click)="onDeleteType2()">
      <mat-icon class="mat-18">delete</mat-icon>
    </button>
  </div>

</div>
