<div class="dialog-content-wrapper">
  <form [formGroup]="searchForm">
    <div fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="gappx" fXFlex="100">
      <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
          <span *ngIf="cargo!== null && cargo!== undefined " class="title-dialog">{{ 'Asignar' | translate }}
            {{' Cargo: '+cargo.titulo}}</span>
          <span *ngIf="cargo == null || cargo == undefined " class="title-dialog">{{ 'Asignar Trabajadores' | translate
            }}</span>
          <button mat-icon-button (click)="dialogRef.close([])" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
          </button>
        </mat-toolbar-row>

        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="gappx" fXFlex="100">
            <label for="search" class="mr-8">
              <mat-icon class="secondary-text">search</mat-icon>
            </label>
            <mat-form-field class="example-full-width" fxFlex="60">
              <input id="search" matInput formControlName="searchPerson" placeholder="{{ 'Search' | translate }}">
            </mat-form-field>
            <button style="margin-left:20px;" fxFlex="20" class="save-product-button" mat-raised-button (click)="onAssignWorkers()"
              [disabled]="newWorkersAssing.length == 0" aria-label="SAVE">
              {{'Save'|translate}}
            </button>
          </div>

        </mat-toolbar-row>
      </mat-toolbar>

    </div>

  </form>

  <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
    <div fxLayout="column" fxLayoutAlign="space-around stretch" fxFlex="100" fxLayoutGap="gappx">
      <div fxLayout="row" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="gappx">
        <mat-selection-list fxFlex="100">
          <mat-list-option class="list-option-person" (click)="oncheckPerson(person)" *ngFor="let person of PersonsFiltered;let i = index">
            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="gappx">
              <img class="avatar-image" *ngIf="person.image" [src]=" imageUrl + person.image" alt="Avatar Image">
              <img class="avatar-image" *ngIf="!person.image" [src]="'assets/images/avatars/avatar-default.png'" alt="Avatar Image">
              <span id="span-person" *ngIf=" personSelected(person)"><strong>{{person.nombre
                  + ' '+
                  person.apellidos }}</strong></span>
              <span id="span-person" *ngIf="!personSelected(person)">{{person.nombre
                +
                '
                '+
                person.apellidos}}</span>
            </div>
          </mat-list-option>
          <!-- <button mat-button (click)="onClickBackward()">
            <mat-icon class="mat-18">arrow_backward</mat-icon>
          </button>
          <button mat-button (click)="onClickForward()">
            <mat-icon class="mat-18">arrow_forward</mat-icon>
          </button> -->
        </mat-selection-list>

      </div>
    </div>


  </div>


</div>
