import { Component, OnInit, OnDestroy, ViewEncapsulation, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../../i18n/en';
import { locale as spanish } from '../../../i18n/es';
import { SelectionModel } from '@angular/cdk/collections';
import { ToastrService } from 'ngx-toastr';
import { DepartmentService } from '../../../../../backend/department/services/department.service';
import * as moment from 'moment';
import {
  UntypedFormBuilder,
  FormGroup,
  Validators,
  AbstractControl
} from '@angular/forms';

export interface HorarioEntradaSalida {
  entrada: string;
  salida: string;
}

@Component({
  selector: 'app-horario-one-week',
  templateUrl: './horario-one-week.component.html',
  styleUrls: ['./horario-one-week.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class HorarioOneWeekComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  checklistSelection = new SelectionModel<any>(true);
  @Input() weeknumber: number;
  @Input() dataInput: any;
  horariosSemanales: any[] = [[], [], [], [], [], [], []];
  horariosSemanalesSend: any = { 'Lunes': [], 'Martes': [], 'Miercoles': [], 'Jueves': [], 'Viernes': [], 'Sabado': [], 'Domingo': [] };
  Week: string[] = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];
  test: HorarioEntradaSalida[] = [];
  showHorariosSemanales = false;
  Day2Number: Map<string, number>;
  Number2Day: Map<number, string>;
  showData = false;

  constructor(private translate: TranslateService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private fb: UntypedFormBuilder, private toastr: ToastrService, private departmentService: DepartmentService) {
    this._unsubscribeAll = new Subject();
    this._fuseTranslationLoaderService.loadTranslations(english, spanish);
    this.Day2Number = new Map<string, number>();
    this.Number2Day = new Map<number, string>();
    // this.test = [{ 'horaEntrada': '08:25 am', 'horaSalida': '04:30 pm' }, { 'horaEntrada': '07:25 am', 'horaSalida': '04:20 pm' }];

  }

  ngOnInit(): void {
    this.buildStructs();
    // console.log(this.horariosSemanales);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  oncheckDay(day): void {
    if (this.checklistSelection.isSelected(day)) {
      this.checklistSelection.deselect(day);
    }
    else {
      this.checklistSelection.select(day);
    }
    this.PrepareFilestoSend();
  }

  onAddHorario(day): void {
    const index = this.Day2Number.get(day);
    const newInterval: HorarioEntradaSalida = { 'entrada': null, 'salida': null };
    this.horariosSemanales[index].push(newInterval);
    this.horariosSemanalesSend[day].push(newInterval);
  }

  buildStructs(): void {
    let init: HorarioEntradaSalida = { 'entrada': '08:25 am', 'salida': '04:30 pm' };
    for (let i = 0; i < this.Week.length; i++) {
      this.Day2Number.set(this.Week[i], i);
      this.Number2Day.set(i, this.Week[i]);
    }

    if (this.dataInput !== undefined || this.dataInput !== null || this.dataInput.length > 0) {
      for (let i = 0; i < this.dataInput.length; i++) {
        const dayData = this.dataInput[i];
        for (let j = 0; j < dayData.horas.length; j++) {
          const entrada = moment.utc(dayData.horas[j].entrada).format('hh:mm a').toString();
          const salida = moment.utc(dayData.horas[j].salida).format('hh:mm a').toString();
          init = { 'entrada': entrada, 'salida': salida };
          this.horariosSemanales[dayData.dia - 1].push(init);
          this.horariosSemanalesSend[this.Number2Day.get(dayData.dia - 1)].push(init);
        }
        this.checklistSelection.select(this.Week[dayData.dia - 1]);
      }
    }

    this.PrepareFilestoSend();
    this.showHorariosSemanales = true;
    this.showData = true;
    

    // console.log(this.horariosSemanalesSend);

  }

  PrepareFilestoSend(): void {
    // const tempCopy = JSON.parse(JSON.stringify(this.horariosSemanalesSend));
    const file2Send: any[] = [];
    const data2Send: any[] = [[], [], [], [], [], [], []];

    // const tempcopy = JSON.parse(JSON.stringify(this.horariosSemanalesSend));
    // console.log(this.horariosSemanales);
    for (let i = 0; i < 7; i++) {
      for (let j = 0; j < this.horariosSemanales[i].length; j++) {
        const entrada = moment.utc(this.horariosSemanales[i][j].entrada, 'hh:mm a').toISOString();
        const salida = moment.utc(this.horariosSemanales[i][j].salida, 'hh:mm a').toISOString();
        data2Send[i].push({ 'entrada': entrada, 'salida': salida });
      }
    }

    // console.log(data2Send);
    for (let i = 0; i < this.Week.length; i++) {
      if (this.checklistSelection.isSelected(this.Week[i])) {
        file2Send.push({ 'dia': this.Day2Number.get(this.Week[i]) + 1, 'Horas': data2Send[i] });
      }
    }
    //console.log(file2Send);
    this.departmentService.sendHorarioOneWeek.emit({ 'id': this.weeknumber, 'data': file2Send });
  }

  onSaveChanges(): void {
    this.PrepareFilestoSend();
    this.toastr.success(
      this.translate.instant('Semana: ' + this.weeknumber.toFixed(0) + ' Editada'),
      'Success!',
      {
        timeOut: 1000,
        progressBar: true,
        positionClass: 'toast-bottom-right'
      }
    );


  }


}
