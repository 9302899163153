import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../backend/user/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { ErrorMessagesService } from '../../../core/error-messages/error-messages.service';
import { CustomValidatorsService } from '../../../core/custom-validators/custom-validators.service';
import { DialogChangeImageComponent } from '../../../shared/images/dialog-change-image/dialog-change-image.component';
import { NationalitiesService } from '../../../core/nationalities/nationalities.service';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { FuseConfirmDialogComponent } from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { DialogAddEditPersonComponent } from '../dialog-add-edit-person/dialog-add-edit-person.component';
import { Persona } from './../../../backend/personal/clases/persona.class';
import { PersonalService } from './../../../backend/personal/services/personal.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';

@Component({
  selector: 'app-dialog-user-add-edit',
  templateUrl: './dialog-user-add-edit.component.html',
  styleUrls: ['./dialog-user-add-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogUserAddEditComponent implements OnInit, OnDestroy {
  isSaving = false;
  isEditing = false;
  hasChangeImage: Boolean = false;
  user: any;
  urlImage: string;
  roles: any[];
  nationalities: string[] = [];
  clockTick: any;
  allPersonas: Persona[] = [];
  showData = false;

  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  filteredNationalities: Observable<string[]>;

  // Private
  private _unsubscribeAll: Subject<any>;

  form: UntypedFormGroup;
  filteredOptions: Observable<Persona[]>;

  constructor(public dialogRef: MatDialogRef<DialogUserAddEditComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public errorMessagesService: ErrorMessagesService,
    private userService: UserService,
    private translate: TranslateService,
    private personalService: PersonalService,
    private fuseProgressBarService: FuseProgressBarService) {
    this.urlImage = environment.imageUrl;

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // Set the defaults
    this.isEditing = _data.isEditing;
    this.allPersonas = _data.allPersonas;

    if (this.isEditing) {
      this.user = _data.user;
    }
    else {
      this.user = {
        persona: null
      };
    }

    this.roles = [
      { id: '1', name: 'Super Admin' },
      { id: '2', name: 'Admin' },
      { id: '3', name: 'Jefe Departamento' },
      { id: '4', name: 'Client' }
    ];
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.createFormGroup();
    if (!this.allPersonas || this.allPersonas === undefined) {
      this.fuseProgressBarService.show();
      this.personalService.getAllPersons().subscribe(data => {
        this.allPersonas = JSON.parse(JSON.stringify(data.result));
        this.showData = true;
        this.mainFunction2ResponsetoFormvalues();
        this.fuseProgressBarService.hide();

      });
    }
    else {
      this.showData = true;
      this.mainFunction2ResponsetoFormvalues();

    }

    this.clockTick = Math.random();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  displayFn(persona?: Persona): string | undefined {
    return (persona) ? persona.nombre + ' ' + persona.apellidos : undefined;
  }

  private _filter(name: string): Persona[] {
    const filterValue = name.toLowerCase();

    return this.allPersonas.filter(option => (option.nombre + ' ' + option.apellidos).toLowerCase().indexOf(filterValue) === 0);
  }

  createFormGroup(): any {
    if (this.isEditing) {
      this.form = this.fb.group({
        nombre: [(this.user.persona) ? this.user.persona.nombre : null, [Validators.required]],
        apellidos: [(this.user.persona) ? this.user.persona.apellidos : null, []],
        username: [(this.user.persona) ? this.user.username : null, [Validators.required]],
        email: [(this.user.persona) ? this.user.persona.email : null, [Validators.required, Validators.email]],
        identidad: [(this.user.persona) ? this.user.persona.identidad : null, [Validators.required]],
        fecha_nacimiento: [(this.user.persona) ? this.user.persona.fecha_nacimiento : null, [Validators.required]],
        persona: [(this.user.persona) ? this.user.persona : null, [Validators.required]],
        descripcion: [this.user.description, []],
        password: [null, []],
        confirmPassword: [null, [CustomValidatorsService.matchOther('password')]],
      });

      this.form.patchValue(<{ [p: string]: any }>this.user);
      this.form.get('password').setValue('');
      this.form.get('confirmPassword').setValue('');
    } else {
      this.form = this.fb.group({
        nombre: [null, [Validators.required]],
        apellidos: [null, []],
        username: [null, [Validators.required]],
        email: [null, [Validators.required, Validators.email]],
        identidad: [null, [Validators.required]],
        fecha_nacimiento: [null, [Validators.required]],
        persona: [null, [Validators.required]],
        descripcion: [null, []],
        password: [null, [Validators.required]],
        confirmPassword: [null, [CustomValidatorsService.matchOther('password')]],
      });
    }
  }

  onSelectedOptions(data): void {
    console.log(data);
  }



  onEdit(): void {
    console.log("pepepepep");
    const userForm = JSON.parse(JSON.stringify(this.form.value));
    userForm.persona.nombre = userForm.nombre;
    userForm.persona.apellidos = userForm.apellidos;
    userForm.persona.identidad = userForm.identidad;
    userForm.persona.fecha_nacimiento = userForm.fecha_nacimiento;
    userForm.persona.email = userForm.email;
    userForm['personaId'] = JSON.parse(JSON.stringify(this.user.persona.id));
    // console.log(userForm);
    if (!this.isEditing) {
      this.fuseProgressBarService.show();
      this.personalService.editPerson(userForm.persona).subscribe(data => {
        this.personalService.personEdited.next(data)
        delete userForm.persona;
        this.userService.createUser(userForm).subscribe(newUsuario => {
          this.userService.newUser.next(newUsuario);
          this.fuseProgressBarService.hide();
          this.dialogRef.close();
          this.toastr.success(
            this.translate.instant('New User Created'),
            'Success!',
            {
              timeOut: 2000,
              progressBar: true,
              positionClass: 'toast-bottom-right'
            }
          );
        },
          error => {
            this.toastr.error(
              this.translate.instant('Error'),
              'Error!',
              {
                timeOut: 2000,
                progressBar: true,
                positionClass: 'toast-bottom-right'
              }
            );
            this.fuseProgressBarService.hide();
          });

      });

    }
    else {
      userForm['id'] = this.user.id;
      // for (let keys in this.user) {
      //   if (userForm[keys] === undefined) {
      //     userForm[keys] = this.user[keys];
      //   }
      // }
      this.fuseProgressBarService.show();
      this.personalService.editPerson(userForm.persona).subscribe(data => {
        this.personalService.personEdited.next(data);
        delete userForm.persona;
        this.userService.editUser(userForm).subscribe(newUsuario => {
          this.userService.userUpdated.next(newUsuario);
          this.fuseProgressBarService.hide();
          this.dialogRef.close();
          this.toastr.success(
            this.translate.instant('New User Edited'),
            'Success!',
            {
              timeOut: 2000,
              progressBar: true,
              positionClass: 'toast-bottom-right'
            }
          );
        },
          error => {
            this.toastr.error(
              this.translate.instant('Error'),
              'Error!',
              {
                timeOut: 2000,
                progressBar: true,
                positionClass: 'toast-bottom-right'
              }
            );
            this.fuseProgressBarService.hide();
          });

      });

    }

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Upload Image
  // -----------------------------------------------------------------------------------------------------

  onChangeImage(): void {
    const dialogRef = this.dialog.open(DialogChangeImageComponent, {
      panelClass: 'app-dialog-change-image',
      width: '30em',
      data: {
        hideBtn: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.hasChangeImage = true;
        this.user.persona.image = result;
      }
    });
  }

  mainFunction2ResponsetoFormvalues(): void {
    this.filteredOptions = this.form.controls['persona'].valueChanges
      .pipe(
        startWith<string | Persona>(''),
        map(value => typeof value === 'string' ? value : value.id),
        map(nombre => nombre ? this._filter(nombre) : this.allPersonas.slice())
      );

    this.form.controls['persona'].valueChanges.subscribe(data => {
      let found = false;
      for (let i = 0; i < this.allPersonas.length; i++) {
        if (this.allPersonas[i].id === data.id) {
          found = true;
          this.form.controls['nombre'].setValue(data.nombre);
          this.form.controls['apellidos'].setValue(data.apellidos);
          this.form.controls['identidad'].setValue(data.identidad);
          this.form.controls['fecha_nacimiento'].setValue(data.fecha_nacimiento);
          this.form.controls['email'].setValue(data.email);
          this.user.persona = JSON.parse(JSON.stringify(data));
          break;
        }
      }
      if (!found) {
        this.form.controls['nombre'].setValue(null);
        this.form.controls['apellidos'].setValue(null);
        this.form.controls['identidad'].setValue(null);
        this.form.controls['fecha_nacimiento'].setValue(null);
        this.form.controls['email'].setValue(null);
        this.user.persona = null;
      }
    });
  }

}
