<form *ngIf="dataReady" [formGroup]="formHorario">
  <div fxLayout="column" fxLayoutAlign="space-around stretch">
    <div fxLayout="row" fxFlex="100" fxLayoutAlign="start start" fxLayoutGap="gappx">
      <span id="cabecera"><strong>Frecuencia de:</strong></span>
    </div>

    <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between start" fxLayoutGap="gappx">
      <span id="trabajo-span"><strong>Trabajo:</strong></span>
      <mat-form-field fxFlex.xs="25" fxFlex="30" class="example-full-width">
        <input type="number" matInput min="0" placeholder="horas" required [formControl]="formHorario.controls['trabajoHoras']">
        <mat-error *ngIf="formHorario.controls['trabajoHoras'].hasError('required')"> this field is<strong>
            required</strong></mat-error>
      </mat-form-field>
      <span id="descanso-span"><strong>Descanso:</strong></span>
      <mat-form-field fxFlex.xs="25" fxFlex="30" class="example-full-width">
        <input type="number" matInput placeholder="horas" min="0" required [formControl]="formHorario.controls['descansoHoras']">
        <mat-error *ngIf="formHorario.controls['descansoHoras'].hasError('required')"> this field is<strong>
            required</strong></mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="gappx">
      <span id="startwork-span"><strong>Entrada:</strong></span>
      <div id="startWork" fxFlex.xs="30" fxFlex="50">
        <input class="max-time-example" matInput placeholder="{{'Start to Work'|translate}}" required [formControl]="formHorario.controls['horaEntrada']"
          [ngxTimepicker]="min" [min]="'04:30 am'">
        <ngx-material-timepicker #min></ngx-material-timepicker>
      </div>
    </div>
  </div>
</form>
<div *ngIf="showEntradas" fxLayout="column" fxLayoutAlign="space-around stretch">
  <div style="margin-top:15px;" *ngFor="let horario of listadoHorarios;let i = index" fxFlex="75" fxFlex.xs="50"
    fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="gappx">
    <span id="EntradasGlabel"><strong>Entrada:</strong></span>
    <span id="horasdeEntrar">{{horario.entrada}}</span>
    <span id=SalidasLabel><strong>Salida:</strong></span>
    <span id="horasdeSalir">{{horario.salida}}</span>
  </div>
</div>
