export const locale = {
  lang: 'en',
  data: {
    'Departments': 'Departments',
    'Back': 'Back',
    'Department Edit': 'Department Edit',
    'Department': 'Department',
    'Department name is required': 'Department name is required',
    'Save': 'Save',
    'Create Department': 'Create Department',
    'Department Name': 'Department Name',
    'Informatics': 'Informatics',
    'Informática': 'Informatics',
    'Informatica': 'Informatics',
    'Basic Info': 'Basic Info',
    'Name': 'Name',
    'Minimal Staff': 'Minimal Staff',
    'Maximum Staff': 'Maximun Staff',
    'Create Place': 'Create Place',
    'Place Created': 'Place Created',
    'Place Edited': 'Place Edited',
    'Department Details': 'Department Details',
    'Workers': 'Workers'
  }
};
