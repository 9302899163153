import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Observable, Subject, fromEvent } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DepartmentService } from '../../../backend/department/services/department.service';
import { Cargo } from '../../../backend/department/clases/cargo.class';
import { fuseAnimations } from '@fuse/animations';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Persona } from '../../../backend/personal/clases/persona.class';
import { SelectionModel } from '@angular/cdk/collections';
import { environment } from '../../../../environments/environment';
import { PersonalService } from 'app/backend/personal/services/personal.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'app-dialog-list-person-to-cargo',
  templateUrl: './dialog-list-person-to-cargo.component.html',
  styleUrls: ['./dialog-list-person-to-cargo.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class DialogListPersonToCargoComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  allPersons: Persona[] = [];
  PersonsFiltered: Persona[] = [];
  checklistSelection = new SelectionModel<Persona>(true);
  cargo: Cargo;
  cargos: Cargo[];
  cargoReparto: any = {};
  personChipCargos: any = {};
  imageUrl = environment.imageUrl;
  newWorkersAssing: Persona[] = [];
  searchForm: UntypedFormGroup;
  pageSizeOptions: any[] = [15, 50, 100, 150];
  pageSize = 10;
  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  start = 0;
  end = 50;


  constructor(public dialogRef: MatDialogRef<DialogListPersonToCargoComponent>,
    @Inject(MAT_DIALOG_DATA) private dataDialog: any,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private departmentService: DepartmentService, private personalService: PersonalService,
    private fb: UntypedFormBuilder) {
    this._unsubscribeAll = new Subject();
    this.allPersons = this.dataDialog.allPersons;
    this.cargo = this.dataDialog.cargo;
    this.PersonsFiltered = this.dataDialog.allPersons;

    this.searchForm = this.fb.group({
      searchPerson: [null, []]
    });

  }

  ngOnInit(): void {
    this.searchForm.controls['searchPerson'].valueChanges.pipe(
      takeUntil(this._unsubscribeAll),
      debounceTime(100),
      distinctUntilChanged()
    )
      .subscribe(data => {
        if (!this.PersonsFiltered) {
          return;
        }
        this.PersonsFiltered = this.myFilter(data.trim().toLowerCase());
      });

  }


  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  oncheckPerson(person): void {
    console.log(person);

    if (this.checklistSelection.isSelected(person)) {
      this.checklistSelection.deselect(person);
      for (let i = 0; i < this.newWorkersAssing.length; i++) {
        if (this.newWorkersAssing[i] === person) {
          this.newWorkersAssing.splice(i, 1);
          break;
        }
      }
      // let i = 0;
      // this.cargoReparto[this.cargo.id].forEach(element => {
      //   if (element.id === person.id) {
      //     this.cargoReparto[this.cargo.id].splice(i, 1);
      //   }
      //   i++;
      // });
      // let j = 0;
      // this.personChipCargos[person.id].forEach(element => {
      //   if (element.id === this.cargo.id) {
      //     this.personChipCargos[person.id].splice(j, 1);
      //   }
      //   j++;
      // });
    }
    else {
      this.checklistSelection.select(person);
      // let found = false;
      // this.cargoReparto[this.cargo.id].forEach(element => {
      //   if (element.id === person.id) {
      //     found = true;
      //   }
      // });
      // if (!found) {
      //   this.cargoReparto[this.cargo.id].push(person);
      // }
      // found = false;
      // this.personChipCargos[person.id].forEach(element => {
      //   if (element.id === this.cargo.id) {
      //     found = true;
      //   }
      // });
      // if (!found) {
      //   this.personChipCargos[person.id].push(this.cargo);
      // }
      // found = false;
      // this.personChipCargos[person.id].forEach(element => {
      //   if (element.id === this.cargo.id) {
      //     found = true;
      //   }
      // });
      // if (!found) {
      //   this.personChipCargos[person.id].push(this.cargo);
      // }
      this.newWorkersAssing.push(person);
    }
    // console.log(this.newWorkersAssing);
  }

  personSelected(person): boolean {
    return this.checklistSelection.isSelected(person);
  }

  buildDistributions(): void {

  }

  matchStr(cadena: string, target: string): boolean {
    //console.log(target.trim());
    if (target.length > cadena.length) {
      return false;
    }
    let finish = false;
    for (let i = 0; i <= cadena.length - target.length && !finish; i++) {
      if (cadena.substring(i, i + target.length) === target) {
        finish = true;
        break;
      }
    }
    return finish;
  }

  myFilter(target: string): Persona[] {
    const newPersonList: Persona[] = [];
    for (let i = 0; i < this.allPersons.length; i++) {
      const item = this.allPersons[i];
      if (item.nombre && item.apellidos) {
        if (this.matchStr(item.nombre.trim().toLowerCase() + item.apellidos.trim().toLowerCase(), target)) {
          newPersonList.push(item);
        }
      }
      else if (item.nombre && !item.apellidos) {
        if (this.matchStr(item.nombre.trim().toLowerCase(), target)) {
          newPersonList.push(item);
        }
      }
      else if (!item.nombre && item.apellidos) {
        if (this.matchStr(item.apellidos.trim().toLowerCase(), target)) {
          newPersonList.push(item);
        }
      }
      else {

      }
    }
    newPersonList.sort((obj1, obj2) => {
      if (obj1.nombre.toLowerCase() > obj2.nombre.toLowerCase()) {
        return 1;
      }
      if (obj1.nombre.toLowerCase() < obj2.nombre.toLowerCase()) {
        return -1;
      }
      else {
        return 0;
      }
    });
    return newPersonList;
  }

  onAssignWorkers(): void {
    this.dialogRef.close(this.newWorkersAssing);
  }

  ////////////////////////////

  /////////////////////////////

}
