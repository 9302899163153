<form [formGroup]="form">
  <div style="margin-top:10px;" fxLayout="row" fxLayoutAlign.xs="start start" fxLayoutAlign="start start">
    <span id="Los-dias" fxFlex.xs="12" fxFlex="12"><strong>El dia:</strong></span>
    <input id="input-meses" required formControlName="dia" fxFlex="12" type="number" matInput min="0" max="30"
      placeholder="dia">
    <span id="label-in" fxFlex="15" fxFlex.xs="10"><strong>ent:</strong></span>
    <div id="HorarioEntrada" fxFlex="30" fxFlex.xs="30">
      <input class="min-time-example" required formControlName="entrada" matInput placeholder="{{'pick'|translate}}"
        [ngxTimepicker]="min">
      <ngx-material-timepicker #min></ngx-material-timepicker>
    </div>
    <span id="label-out" fxFlex="15" fxFlex.xs="10"><strong>sal:</strong></span>
    <div id="HorarioSalida" fxFlex="28" fxFlex.xs="30">
      <input class="max-time-example" required formControlName="salida" matInput placeholder="{{'pick'|translate}}"
        [ngxTimepicker]="max">
      <ngx-material-timepicker #max></ngx-material-timepicker>
    </div>
  </div>
</form>
