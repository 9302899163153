export const locale = {
  lang: 'es',
  data: {
    'Save': 'Guardar',
    'Name': 'Nombre',
    'Last Name': 'Apellidos',
    'DNI': 'CI',
    'Workers': 'Trabajadores',
    'City': 'Provincia',
    'Location': 'Municipio',
    'Address': 'Address',
    'Birthdate': 'Fecha de nacimiento',
    'Phone': 'Teléfono',
    'Movil': 'Móvil',
    'Email': 'Correo',

  }
};
