import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { UserService } from '../../../backend/user/services/user.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { LoggedInUserService } from '../../../core/loggedInUser/logged-in-user.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { locale as spanish } from '../../i18n/en';
import { locale as english } from '../../i18n/es';
import { MatDialog } from '@angular/material/dialog';
import { DialogUserAddEditComponent } from '../../../shared/shared/dialog-user-add-edit/dialog-user-add-edit.component';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  clockTick: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  loggedInUser: any;
  urlImage: string;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   */
  constructor(private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private router: Router,
    public _authService: AuthenticationService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    public _userService: UserService,
    public dialog: MatDialog,
    private _loggedInUserService: LoggedInUserService) {
    this._fuseTranslationLoaderService.loadTranslations(english, spanish);

    this.urlImage = environment.imageUrl;

    // Set the defaults
    this.userStatusOptions = [
      {
        'title': 'Online',
        'icon': 'icon-checkbox-marked-circle',
        'color': '#4CAF50'
      },
      {
        'title': 'Away',
        'icon': 'icon-clock',
        'color': '#FFC107'
      },
      {
        'title': 'Do not Disturb',
        'icon': 'icon-minus-circle',
        'color': '#F44336'
      },
      {
        'title': 'Invisible',
        'icon': 'icon-checkbox-blank-circle-outline',
        'color': '#BDBDBD'
      },
      {
        'title': 'Offline',
        'icon': 'icon-checkbox-blank-circle-outline',
        'color': '#616161'
      }
    ];

    this.languages = [
      {
        id: 'es',
        title: 'Spanish',
        flag: 'es'
      },
      {
        id: 'en',
        title: 'English',
        flag: 'us'
      }
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.loggedInUser = _loggedInUserService.getLoggedInUser();

    this._userService.userUpdated
      .subscribe((user: any) => {
        if (user && this.loggedInUser.id === user.id) {
          // this.loggedInUser.image = user.image;
          this.loggedInUser.persona.nombre = user.persona.nombre;
          this.loggedInUser.persona.apellidos = user.persona.apellidos;
          // this.loggedInUser.cellphone = user.cellphone;
          this.loggedInUser.username = user.persona.username;
          // this.loggedInUser.address = user.address;
          this.loggedInUser.persona.image = user.persona.image;
          this._loggedInUserService.updateUserProfile(user);
          this.clockTick = Math.random();
        }
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    this._translateService.setDefaultLang('Spanish');

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, { 'id': this._translateService.currentLang });
    if ('language' in localStorage) {
      let language = localStorage.getItem('language');
      language = (language) ? language : this.selectedLanguage.id;
      this.selectedLanguage = _.find(this.languages, { 'id': language });
    }
    // Use the selected language for translations
    this._translateService.use(this.selectedLanguage.id);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);

    // Save language in local storage
    localStorage.setItem('language', lang.id);
  }

  logout(): boolean {
    this._authService.logout();
    this.removeCookies();
    this.router.navigate(['auth/login']);
    // location.reload();
    return false;
  }

  removeCookies(): any {
    const res = document.cookie;
    const multiple = res.split(';');
    for (let i = 0; i < multiple.length; i++) {
      const key = multiple[i].split('=');
      document.cookie = key[0] + ' =; expires = Thu, 01 Jan 1970 00:00:00 UTC';
    }
  }

  onProfile(): void {
    this._userService.getUser(this.loggedInUser.id)
      .subscribe(data => {
        // console.log(data.result); 
        const dialogRef = this.dialog.open(DialogUserAddEditComponent, {
          panelClass: 'app-dialog-user-add-edit',
          data: {
            isEditing: true,
            user: data.result,
            allPersonas: null
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            // this.onRefreshData();
          }
        });
      });
  }
}
