export const locale = {
  lang: 'en',
  data: {
    'NAV': {
      'APPLICATIONS': 'Applications',
      'DEPARTMENT': {
        'TITLE': 'Department',
        'BADGE': '0'
      },
      'CARGOS': {
        'TITLE': 'Cargos',
        'BADGE': '0'
      },
      'PERSONAL': {
        'TITLE': 'Employees',
        'BADGE': '0'
      },
      'ASISTENCIA': {
        'TITLE': 'Asistencia',
        'BADGE': '0'
      },
      'USERS': 'Users',
      'TASK': {
        'TITLE': 'Task',
        'BADGE': '0'
      },
    },

  }
};
