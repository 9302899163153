import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ExportPrintService } from './export-print/export-print.service';
import { LoggedInUserService } from './loggedInUser/logged-in-user.service';
import { TokenInterceptorService } from './authentication/token-interceptor.service';
import { UserService } from '../backend/user/services/user.service';
import { UtilsService } from './utils/utils.service';
import { UserClientResolverService } from '../backend/user/services/user-client-resolver.service';
import { UserResolverService } from '../backend/user/services/user-resolver.service';
import { ExportExcelService } from './export-excel/export-excel.service';
import { ErrorMessagesService } from './error-messages/error-messages.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    // AuthenticationService,
    LoggedInUserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    UtilsService,
    UserService,
    UserClientResolverService,
    UserResolverService,
    ExportExcelService,
    ExportPrintService,
    ErrorMessagesService
  ]
})
export class CoreModule {
}
