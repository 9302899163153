import {Injectable} from '@angular/core';

import {
  ValidatorFn, ValidationErrors, AbstractControl, UntypedFormControl
} from '@angular/forms';

@Injectable()
export class CustomValidatorsService {

  constructor() {
  }

  static matchOther(otherControlName: string): ValidatorFn {
    let thisControl: AbstractControl;
    let otherControl: AbstractControl;

    return function (control: AbstractControl): ValidationErrors {
      if (!control.parent) {
        return null;
      }

      if (!thisControl) {
        thisControl = control;
        otherControl = control.parent.get(otherControlName);

        if (!otherControl) {
          throw new Error('matchOtherValidator(): other control is not found in parent group.');
        }

        // TODO: See if we will need to unsubscribe.
        otherControl.valueChanges.subscribe(() => {
          thisControl.updateValueAndValidity();
        });
      }

      if (!otherControl) {
        return null;
      }

      if (otherControl.value !== thisControl.value) {
        return {
          matchOther: {
            otherField: otherControlName
          }
        };
      }

      return null;
    };
  }

  static greaterThan(otherControlName: string): ValidatorFn {
    let thisControl: AbstractControl;
    let otherControl: AbstractControl;

    return function (control: AbstractControl): ValidationErrors {
      if (!control.parent) {
        return null;
      }

      if (!thisControl) {
        thisControl = control;
        otherControl = control.parent.get(otherControlName);

        otherControl.valueChanges
          .subscribe(() => {
            thisControl.updateValueAndValidity();
          });
      }

      if (!otherControl) {
        return null;
      }

      if (thisControl.value <= otherControl.value) {
        return {
          greaterThan: true
        };
      }

      return null;
    };
  }

  static strong(control: UntypedFormControl): ValidationResult {
    const hasNumber = /\d/.test(control.value);
    const hasUpper = /[A-Z]/.test(control.value);
    const hasLower = /[a-z]/.test(control.value);
    const valid = hasUpper && hasLower;

    if (!valid) {
      // return what´s not valid
      return {strong: true};
    }
    return null;
  }
}

export interface ValidationResult {
  [key: string]: boolean;
}
