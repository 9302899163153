<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">
        {{ 'Upload Image' | translate }}
      </span>
      <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar style="padding-bottom: 0px !important;">

    <!-- <div fxLayout="row raw" fxLayoutAlign="center center">
      <div fxLayout="row" fxLayoutAlign="center" class="cf-margin-bottom-20">
        <img *ngIf="loadImage" class="car-image" [src]="base64textString" alt="">
       
       
      </div>

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
        <button mat-button (click)="openFileBrowser($event)">
          <mat-icon class="cf-margin-right-10"> photo_camera</mat-icon>
        </button>

        <button mat-button (click)="rotateBase64Image(base64textString,-90)">
          <mat-icon class="mat-18">rotate_left</mat-icon>
        </button>

        <button mat-button (click)="rotateBase64Image(base64textString,90)">
          <mat-icon class="mat-18">rotate_right</mat-icon>
        </button>

        <mat-error *ngIf="showErrorImage">
          La imagen sobrepasa los <strong>800 Kb</strong>
        </mat-error>
      </div>
    </div> -->
    <!-- <input type="file" class="hide-style" id="filePicker" (change)="handleFileSelect($event)">
    <button mat-button (click)="openFileBrowser($event)">
      <mat-icon class="cf-margin-right-10"> photo_camera</mat-icon>
    </button> -->
    <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="gappx">
      <input type="file" class="hide-style" id="filePicker" (change)="fileChangeEvent($event)" />
      <button fxFlex="10" mat-raised-button color="primary" (click)="openFileBrowser($event)">
        <mat-icon class="cf-margin-right-10"> photo_camera</mat-icon>
      </button>
      <button fxFlex="10" mat-raised-button color="primary" (click)="rotateLeft()">
        <mat-icon class="mat-18">rotate_left</mat-icon>
      </button>
      <button fxFlex="10" mat-raised-button color="primary" (click)="rotateRight()">
        <mat-icon class="mat-18">rotate_right</mat-icon>
      </button>
    </div>

    <div fxLayout="row" style="margin-top:10px;" fxLayoutAlign="center center" fxLayoutGap="gappx">
      <img *ngIf="!loadImage" class="car-image" [src]="'../../../../assets/images/avatars/profile.jpg'" alt="">
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
      <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 4"
        [resizeToWidth]="300" [cropperMinWidth]="300" [onlyScaleDown]="true" [roundCropper]="false" format="png"
        outputType="base64" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()" [style.display]="showCropper ? null : 'none'"></image-cropper>
    </div>

    <!-- <img [src]="croppedImage" /> -->

  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end end">

    <button mat-raised-button (click)="onSave()" class="save-button mat-accent" [disabled]="!croppedImage">
      <span>{{ 'Cargar' | translate }}</span>
    </button>

  </div>
</div>
