import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormArray,
  Validators,
  AbstractControl
} from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { buildMapFromSet } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { group } from '@angular/animations';
import { DialogChangeImageComponent } from '../../../shared/images/dialog-change-image/dialog-change-image.component';
import { Persona } from '../../../backend/personal/clases/persona.class';
import { PersonalService } from '../../../backend/personal/services/personal.service';
import { environment } from '../../../../environments/environment';
import { FuseProgressBarService } from '../../../../@fuse/components/progress-bar/progress-bar.service';
import { DepartmentService } from '../../../backend/department/services/department.service';
import { Departamento } from '../../../backend/department/clases/department.class';
import { Cargo } from '../../../backend/department/clases/cargo.class';
import { ContentObserver } from '@angular/cdk/observers';
import { DialogListCargosComponent } from '../dialog-list-cargos/dialog-list-cargos.component';
import { DialogAddCargoComponent } from './../dialog-add-cargo/dialog-add-cargo.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../i18n/en';
import { locale as spanish } from '../i18n/es';



@Component({
  selector: 'app-dialog-add-edit-person',
  templateUrl: './dialog-add-edit-person.component.html',
  styleUrls: ['./dialog-add-edit-person.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class DialogAddEditPersonComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  isEditing = false;
  isImageLoaded = false;
  imagePerson = '';
  form: UntypedFormGroup;
  phone_numbers: UntypedFormArray;
  selectedPerson: Persona;
  urlImage = environment.imageUrl;
  allDepartments: Departamento[] = [];
  allCargos: Cargo[] = [];
  DepartamentosCargosReady = false;
  DepartamentosCargos: any = {};
  isOnlyBasicInfo = false;
  geodata: any[] = [];
  paises = ['Cuba'];
  Municipios: any[] = [];

  filteredProvincias: Observable<any[]>;
  filteredMunicipios: Observable<any[]>;


  constructor(public dialogRef: MatDialogRef<DialogAddEditPersonComponent>,
    @Inject(MAT_DIALOG_DATA) private dataDialog: any,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private personService: PersonalService,
    private fuseProgressBarService: FuseProgressBarService,
    private departmentService: DepartmentService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private translate: TranslateService) {

    this._fuseTranslationLoaderService.loadTranslations(english, spanish);
    this._unsubscribeAll = new Subject();
    this.selectedPerson = this.dataDialog.selectedPerson;
    // console.log(this.selectedPerson);
    this.isEditing = this.dataDialog.isEditing;
    this.fuseProgressBarService.show();
    if (this.dataDialog.isOnlyBasicInfo !== undefined) {
      this.isOnlyBasicInfo = this.dataDialog.isOnlyBasicInfo;
    }
  }

  ngOnInit(): void {

    if (!this.isOnlyBasicInfo) {
      this.departmentService.getAllDepartments().subscribe(data => {
        this.allDepartments = data.data;
        this.fuseProgressBarService.hide();
        this.buildCargosDepartamentos();
        // console.log(this.allDepartments);
      });
      this.departmentService.getAllCargos().subscribe(data => {
        this.allCargos = data.data;
      });
    }
    else {
      this.fuseProgressBarService.hide();
    }

    this.personService.getGeoData().subscribe(data => {
      this.geodata = data;
      this.buildForm();
    });

    this.filteredProvincias = this.form.controls['departamento'].valueChanges
      .pipe(
        startWith<string | any>(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.geodata.slice())
      );

    this.filteredMunicipios = this.form.controls['localidad'].valueChanges
      .pipe(
        startWith<string | any>(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterMunicipios(name) : this.Municipios.slice())
      );

    this.form.controls['departamento'].valueChanges.subscribe(newProvincia => {
      console.log(newProvincia);
      let found = false;
      for (let i = 0; i < this.geodata.length; i++) {
        if (this.geodata[i].name === newProvincia) {
          this.Municipios = JSON.parse(JSON.stringify(this.geodata[i].departments));
          found = true;
          break;
        }
      }
      if (!found) {
        this.Municipios = [];
      }
      this.form.controls['localidad'].setValue('');
    });


  }




  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onCreate(): void {
    this.fuseProgressBarService.show();
    const newpersona: Persona = this.form.value;
    console.log(newpersona);
    //newpersona['departamento'] = newpersona['departamento'].name;
    const cargosDepartmentPersonasArray: any[] = [];

    if (!this.isEditing) {
      newpersona.image = this.imagePerson;
      this.personService.createPerson(newpersona).subscribe(value => {
        for (const key in this.DepartamentosCargos) {
          if (key) {
            const cargos = this.DepartamentosCargos[key];
            cargos.forEach(element => {
              const temp = { 'DepartamentoId': key, 'CargoId': element.id, 'PersonaId': value.id };
              cargosDepartmentPersonasArray.push(temp);
            });
          }
        }
        this.departmentService.vincularPersonaCargoDepartament(cargosDepartmentPersonasArray).subscribe(data => {
          this.personService.newPerson.next(value);
          this.toastr.success(
            this.translate.instant('Person Created'),
            'Success!',
            {
              timeOut: 2000,
              progressBar: true,
              positionClass: 'toast-bottom-right'
            }
          );
          this.fuseProgressBarService.hide();
          this.dialogRef.close(true);
        });

      },
        error => {
          const errorMessage = error.error;
          if (Object.keys(errorMessage)[0]) {
            const errorCat = Object.keys(errorMessage)[0];
            this.toastr.error(errorMessage[errorCat][0], 'Error', {
              timeOut: 2000,
              progressBar: true,
              positionClass: 'toast-bottom-right'
            });
            this.fuseProgressBarService.hide();
          }
        }
      );
    }
    else {
      newpersona.id = this.selectedPerson.id;
      if (this.isImageLoaded) {
        newpersona.image = this.imagePerson;
      }
      else {
        newpersona.image = this.selectedPerson.image;
      }

      this.personService.editPerson(newpersona).subscribe(value => {
        for (const key in this.DepartamentosCargos) {
          if (key) {
            const cargos = this.DepartamentosCargos[key];
            cargos.forEach(element => {
              const temp = { 'DepartamentoId': key, 'CargoId': element.id, 'PersonaId': value.id };
              cargosDepartmentPersonasArray.push(temp);
            });
          }
        }
        this.departmentService.vincularPersonaCargoDepartament(cargosDepartmentPersonasArray).subscribe(data => {
          this.personService.personEdited.next(value);
          this.toastr.success(
            this.translate.instant('Person Edited'),
            'Success!',
            {
              timeOut: 2000,
              progressBar: true,
              positionClass: 'toast-bottom-right'
            }
          );
          this.fuseProgressBarService.hide();
          this.dialogRef.close(true);

        });
      },
        error => {
          const errorMessage = error.error;
          if (Object.keys(errorMessage)[0]) {
            const errorCat = Object.keys(errorMessage)[0];
            this.toastr.error(errorMessage[errorCat][0], 'Error', {
              timeOut: 2000,
              progressBar: true,
              positionClass: 'toast-bottom-right'
            });
            this.fuseProgressBarService.hide();
          }
        }
      );
    }

  }

  buildForm(): void {

    if (!this.isEditing) {
      this.form = this.fb.group({
        nombre: [null, [Validators.required]],
        apellidos: [null, [Validators.required]],
        fecha_nacimiento: [null, [Validators.required]],
        direccion: [null, [Validators.required]],
        localidad: [null, [Validators.required, Validators.minLength(3)]],
        departamento: [null, [Validators.required]],
        pais: [null, [Validators.required]],
        zipcode: [null, []],
        movil: [null, []],
        telefono: [null, []],
        image: [null, []],
        identidad: [null, [Validators.required, Validators.minLength(11), Validators.maxLength(11)]],
        email: [null, [Validators.email]]
      });
    }
    else {
      console.log(this.selectedPerson);
      if (this.imagePerson && this.imagePerson.length > 0) {
        this.selectedPerson.image = this.imagePerson;
      }
      this.form = this.fb.group({
        nombre: [this.selectedPerson.nombre, [Validators.required]],
        apellidos: [this.selectedPerson.apellidos, [Validators.required]],
        fecha_nacimiento: [this.selectedPerson.fecha_nacimiento, [Validators.required]],
        direccion: [this.selectedPerson.direccion, [Validators.required]],
        localidad: [this.selectedPerson.localidad, [Validators.required, Validators.minLength(3)]],
        departamento: [this.selectedPerson.departamento, [Validators.required]],
        pais: [this.selectedPerson.pais, [Validators.required]],
        zipcode: [this.selectedPerson.zipcode, []],
        movil: [this.selectedPerson.movil, []],
        telefono: [this.selectedPerson.telefono, []],
        image: [this.selectedPerson.image, []],
        identidad: [this.selectedPerson.identidad, [Validators.required, Validators.min(10000000000), Validators.max(99999999999)]],
        email: [this.selectedPerson.email, [Validators.email]]
      });

      for (let i = 0; i < this.geodata.length; i++) {
        if (this.geodata[i].name === this.selectedPerson.departamento) {
          this.Municipios = JSON.parse(JSON.stringify(this.geodata[i].departments));
        }
      }
      console.log(this.Municipios);

    }

  }

  onloadImage(): void {
    this.imagePerson = '';
    this.isImageLoaded = false;
    const dialogImage = this.dialog.open(DialogChangeImageComponent, {
      panelClass: 'app-dialog-change-image',
      width: '10cm',
      data: {
      }
    });
    dialogImage.afterClosed().subscribe(result => {
      if (result) {
        this.imagePerson = result;
        this.isImageLoaded = true;
      }
    });

  }


  buildCargosDepartamentos(): void {
    for (let i = 0; i < this.allDepartments.length; i++) {
      this.DepartamentosCargos[this.allDepartments[i].id] = [];
    }

    if (this.isEditing) {
      for (let i = 0; i < this.selectedPerson.personaCargoDepartamentos.length; i++) {
        const cargoDepartamento = this.selectedPerson.personaCargoDepartamentos[i].cargoDepartamento;
        this.DepartamentosCargos[cargoDepartamento.departamento.id].push(cargoDepartamento.cargo);
      }
    }
    this.DepartamentosCargosReady = true;

  }
  onAddCargo(departamento: Departamento): void {
    const dialogRefCargo = this.dialog.open(DialogListCargosComponent, {
      panelClass: 'app-dialog-list-cargos',
      width: '12cm',
      data: {
        cargos: this.allCargos
      }
    });
    dialogRefCargo.afterClosed().subscribe(result => {
      if (result) {

        for (let i = 0; i < result.length; i++) {
          let found = false;
          for (let j = 0; j < this.DepartamentosCargos[departamento.id].length; j++) {
            if (result[i].id === this.DepartamentosCargos[departamento.id][j].id) {
              this.DepartamentosCargos[departamento.id][j][j] = result[i];
              found = true;
            }
          }
          if (!found) {
            this.DepartamentosCargos[departamento.id].push(result[i]);
          }
        }

      }
    });
  }
  onDeleteCargo(departamento: Departamento): void {
    this.DepartamentosCargos[departamento.id].pop();

  }

  onClickCargo(cargo: Cargo): void {
    const dialogRef = this.dialog.open(DialogAddCargoComponent, {
      panelClass: 'app-dialog-add-cargo',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: {
        isEditing: true,
        selectedCargo: cargo
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });

  }

  //////////////////////
  displayFn(provincia?: any): string | undefined {
    return provincia ? provincia : undefined;
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.geodata.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  displayMunicipios(municipio?: any): string | undefined {
    return municipio ? municipio : undefined;
  }

  private _filterMunicipios(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.Municipios.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

}



