<div fxLayout="column" *ngIf="showData" fxLayoutAlign="space-around stretch">
  <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="gappx">
    <button id="AddSemanaBtn" mat-button (click)="onAddSemana()">
      <mat-icon class="mat-18">add</mat-icon>
    </button>
    <button id="DeleteSemanaBtn" mat-button (click)="onDeleteSemana()">
      <mat-icon class="mat-18">delete</mat-icon>
    </button>
  </div>
  <mat-card style="margin-top:10px;" *ngFor="let item of iteradorArray" fxFlex=100>
    <mat-card-content fxFlex="100">
      <app-horario-one-week [weeknumber]="item" [dataInput]="(dataInput.length>0 && dataInput!=undefined )?dataInput[item-1].horarioDiaSemanas:[]"></app-horario-one-week>
    </mat-card-content>
  </mat-card>
</div>
