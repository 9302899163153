<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

  <div class="logo">
    <span class="logo-text secondary-text">
      <!--TODO: VCA: Descomentar-->
      <img style="width:50px;height:38px" class="logo-icon" src="assets/images/logos/guajirito.png">
    </span>
    <h2 class="tituloEmpresa">Guajirito</h2>
  </div>

  <div class="buttons">

    <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
      <mat-icon class="secondary-text">menu</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
      <mat-icon class="secondary-text">arrow_back</mat-icon>
    </button>

  </div>

</div>


<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground" fusePerfectScrollbar
  [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

  <div class="user" fxLayout="column" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div *ngIf="loggedInUser && !loggedInUser.persona">
      <div *ngIf="loggedInUser" class="h3 username">{{ loggedInUser.nombre + ' ' +
        loggedInUser.apellidos
        }}</div>
      <div *ngIf="loggedInUser" class="h5 email hint-text mt-8">{{ loggedInUser.email }}</div>
      <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
        <img *ngIf="loggedInUser && !loggedInUser.image" class="avatar" src="assets/images/avatars/profile.jpg">
        <img *ngIf="loggedInUser && loggedInUser.image" class="avatar" src="{{ urlImage + loggedInUser.image }}?d={{clockTick}}">
      </div>
    </div>

    <div *ngIf="loggedInUser && loggedInUser.persona">
      <div *ngIf="loggedInUser" class="h3 username">{{ loggedInUser.persona.nombre + ' ' +
        loggedInUser.persona.apellidos
        }}</div>
      <div *ngIf="loggedInUser" class="h5 email hint-text mt-8">{{ loggedInUser.persona.email }}</div>
      <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
        <img *ngIf="loggedInUser && !loggedInUser.persona.image" class="avatar" src="assets/images/avatars/profile.jpg">
        <img *ngIf="loggedInUser && loggedInUser.persona.image" class="avatar" src="{{ urlImage + loggedInUser.persona.image }}?d={{clockTick}}">
      </div>
    </div>

  </div>

  <div class="navbar-content">
    <fuse-navigation layout="vertical"></fuse-navigation>
  </div>

</div>
