import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {LoggedInUserService} from '../core/loggedInUser/logged-in-user.service';

@Injectable({
  providedIn: 'root'
})
export class BackendGuard implements CanActivate {

  constructor(private loggeInUser: LoggedInUserService, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.loggeInUser.getLoggedInUser()) {
      return true;
    }

    this.router.navigate(['auth/login']);
    return false;
  }
}
