<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title-dialog">{{ 'Listado de Cargos' | translate }}</span>
      <button mat-icon-button (click)="dialogRef.close([])" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
    <div fxLayout="column" fxLayoutAlign="space-around stretch">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="gappx" fXFlex="100">
        <label for="search" class="mr-8">
          <mat-icon class="secondary-text">search</mat-icon>
        </label>
        <mat-form-field floatLabel="never" fxFlex="80">
          <input id="search" matInput #FilterCargos placeholder="{{ 'Search' | translate }}">
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100" fxLayoutGap="gappx">
        <mat-card fxFlex="100">
          <mat-card-content>
            <mat-list>
              <mat-list-item class="list-item">
                <div fxLayout="row" fxLayoutAlign="space-between center" class="list-item-div">
                  <span (click)="onSelectAllCargos();" style="width: 100%;"><strong>All</strong></span>
                  <mat-checkbox color="primary" [(ngModel)]="selectedallCargos" (change)="onSelectAllCargos(true)"></mat-checkbox>
                </div>
              </mat-list-item>
              <mat-list-item *ngFor="let cargo of cargos" class="list-item" (click)="onSelectCargo(cargo,true)">
                <div fxLayout="row" fxLayoutAlign="space-between center" class="list-item-div">
                  <span *ngIf="selectedCargos[cargo.id]"><strong>{{cargo.titulo}}</strong></span>
                  <span *ngIf="!selectedCargos[cargo.id]">{{cargo.titulo}}</span>
                  <mat-checkbox color="primary" [(ngModel)]="selectedCargos[cargo.id]" (click)="$event.stopPropagation()"
                    (change)="onSelectCargo(cargo,false)">
                    <button mat-icon-button *ngIf="selectedCargos[cargo.id]" (click)="onCreateCargo(true,cargo)">
                      <mat-icon>create</mat-icon>
                    </button>
                  </mat-checkbox>
                </div>
              </mat-list-item>
            </mat-list>
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-button (click)="onCreateCargo(false,null)" [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}">
              <mat-icon class="mat-18">add</mat-icon>
              <strong>Nuevo</strong>
            </button>
          </mat-card-actions>

        </mat-card>

      </div>


    </div>
  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end end ">
    <button mat-raised-button (click)="dialogRef.close(newcargosSelected)" class="save-button mat-accent" aria-label="SAVE">
      {{'Save'|translate}}
    </button>
  </div>

</div>
