import { Injectable, EventEmitter } from '@angular/core';
import { Persona } from '../clases/persona.class';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Observable, Observer, Subject } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

export interface QueryParams {
  offset?: number;
  limit?: number;
  searchValue?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PersonalService {
  url = environment.apiUrl + 'persona/';
  urlId = this.url + ':id';
  httpOptions = {};
  newPerson: Subject<Persona>;
  personEdited: Subject<Persona>;
  personsDelete: Subject<any>;
  geoData: any[] = [];
  allPersonas: any[] = [];

  constructor(private httpClient: HttpClient) {
    this.newPerson = new Subject<Persona>();
    this.personEdited = new Subject<Persona>();
    this.personsDelete = new Subject<any>();

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this._getGeoData().subscribe(values => {
      this.geoData = values;
      //console.log(this.geoData);
    });

    this._getAllPersons().subscribe(values => {
      this.allPersonas = values.result;
    });

    this.newPerson.subscribe(data => {
      this.getPersonabyId(data.id).subscribe(persona => {
        this.allPersonas.push(persona.result);
      });
    });

    this.personEdited.subscribe(data => {
      if (data) {
        this.getPersonabyId(data.id).subscribe(persona => {
          // console.log(persona);
          for (let i = 0; i < this.allPersonas.length; i++) {
            if (this.allPersonas[i].id === persona.result.id) {
              this.allPersonas[i] = persona.result;
              break;
            }
          }
        });
      }
    });

    this.personsDelete.subscribe(data => {
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.allPersonas.length; j++) {
          if (this.allPersonas[j].id === data[i].id) {
            this.allPersonas.splice(j, 1);
          }
        }
      }
    });

  }

  _getAllPersons(): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set(
      'offset',
      '0'
    )
    httpParams = httpParams.set(
      'limit',
      '350'
    )

    return this.httpClient.get(this.url, { params: httpParams });
  }

  getAllPersons(queryparams?): Observable<any> {
    if (queryparams === undefined || queryparams === null) {
      if (this.allPersonas.length === 0) {
        let httpParams = new HttpParams();
        httpParams = httpParams.set(
          'offset',
          '0'
        )
        httpParams = httpParams.set(
          'limit',
          '350'
        )
        console.log('porque');
        return this.httpClient.get(this.url, { params: httpParams });
      }
      else {
        console.log('YO SI');
        return Observable.create((observer: Observer<any>) => {
          observer.next(JSON.parse(JSON.stringify({ 'result': this.allPersonas })));
        });
      }
    }
    else {
      let httpParams = new HttpParams();
      httpParams = httpParams.set(
        'offset',
        queryparams.offset
      )

      httpParams = httpParams.set(
        'limit',
        queryparams.limit
      )
      if (queryparams.searchValue) {
        httpParams = httpParams.set(
          'filter',
          queryparams.searchValue
        );
      }
      return this.httpClient.get(this.url, { params: httpParams });
    }

  }



  createPerson(person: Persona): Observable<Persona> {
    return this.httpClient.post(this.url, person, this.httpOptions);
  }

  editPerson(person: Persona): Observable<Persona> {
    return this.httpClient.put(this.url + person.id, person, this.httpOptions);
  }

  getPersonabyId(id): Observable<any> {
    return this.httpClient.get(this.url + id, this.httpOptions);
  }

  _getGeoData(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + 'values', this.httpOptions);
  }

  getGeoData(): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      observer.next(this.geoData);
    });
  }

  deltetePerson(data): Observable<any> {
    if (typeof (data) === 'string') {
      return this.httpClient.delete(this.url + data, this.httpOptions)
    }
    else {
      return this.httpClient.delete(this.url + data.id, this.httpOptions);
    }

  }

}
