import { Injectable, EventEmitter } from '@angular/core';
import { Departamento } from '../clases/department.class';
import { environment } from '../../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { Cargo } from './../clases/cargo.class';
import { Area } from './../clases/area.class';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  url = environment.apiUrl + 'departamento/';
  urlId = this.url + ':id';
  urlCargo = environment.apiUrl + 'cargo/';
  urlArea = environment.apiUrl + 'area/';
  urlCargoDepartamento = environment.apiUrl + 'cargo/departamentos/';
  urlAreaDepartamento = environment.apiUrl + 'area/departamentos/';
  urlPersonaCargoDepartamento = environment.apiUrl + 'persona/cargoDepartamentos/';
  httpOptions = {};
  newcargo = new EventEmitter<Cargo>();
  newArea = new EventEmitter<Area>();
  selectedcargo = new EventEmitter<Cargo>();
  checkedCargo = new EventEmitter<Cargo>();
  newCargosReparto = new EventEmitter<any>();
  onselectAreas = new EventEmitter<any>();
  personlistAssigned = new Subject<any>();
  newDepartmentCreate = new Subject<any>();
  ////////////////////////////////////////
  giveNewHorario = new EventEmitter<any>();
  takeNewHorario = new EventEmitter<any>();
  giveNewHorarioSemanal = new EventEmitter<any>();
  sendHorarioOneWeek = new EventEmitter<any>();
  sendHorarioOneMesType1 = new EventEmitter<any>();
  sendHorarioOneMesType2 = new EventEmitter<any>();

  ////////////////////////////////////////
  constructor(private httpClient: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        // 'Authorization': 'Bearer ' + base64EncodedPw
      })
    };
  }

  getAllDepartments(): Observable<any> {
    return this.httpClient.get<any>(this.url, this.httpOptions);
  }

  getDepartment(id: any): Observable<any> {
    return this.httpClient.get<any>(
      this.urlId.replace(':id', id),
      this.httpOptions
    );
  }

  createDepartment(department): Observable<Departamento> {
    return this.httpClient.post(this.url, department, this.httpOptions);
  }

  editDepartment(department): Observable<Departamento> {
    return this.httpClient.put(this.url + department.id, department, this.httpOptions);
  }

  getAllCargos(): Observable<any> {
    return this.httpClient.get<any>(this.urlCargo, this.httpOptions);
  }

  createCargo(cargo: Cargo): Observable<Cargo> {
    return this.httpClient.post(this.urlCargo, cargo, this.httpOptions);
  }

  editCargo(cargo: Cargo): Observable<Cargo> {
    return this.httpClient.put(this.urlCargo + cargo.id, cargo, this.httpOptions);
  }

  vincularCargoDepartamento(data: any): Observable<any> {
    return this.httpClient.put(this.urlCargoDepartamento, data, this.httpOptions);
  }

  eliminarPersonaCargoDepartament(data): Observable<any> {
    if (typeof (data) === 'string') {
      return this.httpClient.delete(this.urlPersonaCargoDepartamento + data, this.httpOptions);
    }
    else {
      return this.httpClient.delete(this.urlPersonaCargoDepartamento + data.id, this.httpOptions);
    }
  }

  vincularPersonaCargoDepartament(data: any): Observable<any> {
    return this.httpClient.put(this.urlPersonaCargoDepartamento, data, this.httpOptions);
  }
  vincularAreaDepartamento(data: any): Observable<any> {
    return this.httpClient.put(this.urlAreaDepartamento, data, this.httpOptions);
  }

  getAllArea(): Observable<any> {
    return this.httpClient.get<any>(this.urlArea, this.httpOptions);
  }

  createArea(area: Area): Observable<any> {
    return this.httpClient.post(this.urlArea, area, this.httpOptions);
  }

  editArea(area: Area): Observable<any> {
    return this.httpClient.put(this.urlArea + area.id, area, this.httpOptions);

  }

  getAllPersonsDepartament(departament: Departamento): Observable<any> {
    return this.httpClient.get(this.url + departament.id + '/personas', this.httpOptions);

  }

  getAllCargosDepartament(departament: Departamento): Observable<any> {
    return this.httpClient.get(this.url + departament.id + '/cargos', this.httpOptions);
  }

  getAllAreasDepartament(departament: Departamento): Observable<any> {
    return this.httpClient.get(this.url + departament.id + '/areas', this.httpOptions);
  }

  deleteCargo(data): Observable<any> {
    if (typeof (data) === 'string') {
      return this.httpClient.delete(this.urlCargo + data, this.httpOptions);
    }
    else {
      return this.httpClient.delete(this.urlCargo + data.id, this.httpOptions);
    }

  }

  deleteDepartment(data): Observable<any> {
    if (typeof (data) === 'string') {
      return this.httpClient.delete(this.url + data, this.httpOptions);
    }
    else {
      return this.httpClient.delete(this.url + data.id, this.httpOptions);
    }

  }


}
