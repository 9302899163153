import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, OnChanges, DoCheck } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../../i18n/en';
import { locale as spanish } from '../../../i18n/es';
import { DepartmentService } from '../../../../../backend/department/services/department.service';
import * as moment from 'moment';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  AbstractControl,
  FormArray
} from '@angular/forms';

export interface MesType1 {
  dia: number;
  horarioEntrada: string;
  horarioSalida: string;
}
@Component({
  selector: 'app-horario-one-mes-type1',
  templateUrl: './horario-one-mes-type1.component.html',
  styleUrls: ['./horario-one-mes-type1.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HorarioOneMesType1Component implements OnInit, OnDestroy {
  @Input() id;
  private _unsubscribeAll: Subject<any>;
  listofDias: any[] = [];
  // ArrayListDias: MesType1[] = [];
  form: UntypedFormGroup;
  /// ArrayListDias: FormArray;


  constructor(private translate: TranslateService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private fb: UntypedFormBuilder, private departmentService: DepartmentService) {
    this._unsubscribeAll = new Subject();
    this._fuseTranslationLoaderService.loadTranslations(english, spanish);
  }

  ngOnInit(): void {
    this.buildForm();
    this.form.valueChanges.subscribe(data => {
      // console.log(data);
      const temp: any = {};
      temp['id'] = data.id;
      temp['dia'] = data.dia;
      temp['entrada'] = moment.utc(data.entrada, 'hh:mm a').toISOString();
      temp['salida'] = moment.utc(data.salida, 'hh:mm a').toISOString();
      this.departmentService.sendHorarioOneMesType1.emit(temp);
    });
  }



  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ShowListofDias(): void {
    // this.listofDias = this.ArrayListDias.value;
    // if (this.listofDias.length === 0) {
    //   return ' ';
    // }
    // let temp = '';
    // temp = 'Los días:' + '  ' + this.listofDias[0].dia.toFixed(0) + ' ';
    // for (let i = 1; i < this.listofDias.length; i++) {
    //   temp = temp + ',' + ' ' + this.listofDias[i].dia.toFixed(0) + ' ';
    // }
    // temp += ' ' + ' del mes';
    // return temp;
  }

  buildForm(): void {
    this.form = this.fb.group({
      id: [this.id, []],
      dia: [1, []],
      entrada: [null, []],
      salida: [null, []],
    });
    const temp: any = {};
    temp['id'] = this.id;
    temp['dia'] = 1;
    temp['entrada'] = moment.utc(null, 'hh:mm a').toISOString();
    temp['salida'] = moment.utc(null, 'hh:mm a').toISOString();
    this.departmentService.sendHorarioOneMesType1.emit(temp);
  }

  // onAdd(): void {
  //   this.ArrayListDias = this.form.get('ArrayListDias') as FormArray;
  //   this.ArrayListDias.push(this.createItem());
  // }

  // onDelete(): void {
  //   if (this.ArrayListDias.length > 1) {
  //     this.ArrayListDias.removeAt(this.ArrayListDias[this.ArrayListDias.length - 1]);
  //   }
  // }

}
