import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

import { IUser } from '../classes/user.class';
import { LoggedInUserService } from '../loggedInUser/logged-in-user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  userUrl = environment.apiUrl + 'usuario' + '/auth';

  constructor(
    private httpClient: HttpClient,
    private loggedInUserService: LoggedInUserService
  ) {}

  login(user: string, password: string): any {
    // const base64EncodedPw = btoa(user + ':' + password);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        // 'Authorization': 'Bearer ' + base64EncodedPw
      })
    };

    const userSend = {
      username: user,
      password: password
    };
    console.log(userSend);

    return this.httpClient.post<any>(this.userUrl, userSend, httpOptions);
  }

  logout(): any {
    localStorage.removeItem('user');
    this.loggedInUserService.setLoggedInUser(null);
    localStorage.clear();
  }

  getUser(): any {
    return JSON.parse(localStorage.getItem('user'));
  }

  isLoggedIn(): boolean {
    return this.getUser() !== null;
  }
}

export const AUTH_PROVIDERS: Array<any> = [
  {
    provide: AuthenticationService,
    useClass: AuthenticationService
  }
];
