<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span *ngIf="!isEditing" class="title dialog-title">{{ 'Create Person' | translate }}</span>
      <span *ngIf="isEditing" class="title dialog-title">{{ 'Edit Person' | translate }}</span>
      <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
    <mat-horizontal-stepper [linear]="true" #stepper>
      <mat-step [completed]="form.valid" [editable]="form.valid">
        <ng-template matStepLabel>{{'Basic Info'|translate}}</ng-template>
        <form [formGroup]="form" class="example-full-width">
          <div fxLayout="column" fxLayoutAlign="space-around stretch">
            <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="gappx">
              <div *ngIf="isEditing" (click)="onloadImage()">
                <img class="person-avatar-image" *ngIf="!isImageLoaded && selectedPerson.image " [src]=" urlImage + selectedPerson.image"
                  alt="Avatar Image">
                <img class="person-avatar-image" *ngIf="isImageLoaded" [src]="imagePerson" alt="Avatar Image">
                <img class="person-avatar-image" *ngIf="!isImageLoaded && !selectedPerson.image  " [src]="'../../../../assets/images/avatars/avatar-default.png'"
                  alt="Avatar Image">
              </div>
              <div *ngIf="!isEditing" (click)="onloadImage()">
                <img class="person-avatar-image" *ngIf="isImageLoaded" [src]="imagePerson" alt="Avatar Image">
                <img class="person-avatar-image" *ngIf="!isImageLoaded" [src]="'../../../../assets/images/avatars/profile.jpg'"
                  alt="Avatar Image">
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign.xs="end end" fxLayoutAlign="end end" fxLayoutGap="gappx">
              <button id="ButtonPhoto" mat-raised-button (click)="onloadImage()" color="primary">
                <mat-icon class="mat-36">photo_camera</mat-icon>
              </button>
            </div>
            <div fxLayout="row" class="cf-margin-top-10" fxLayoutAlign="space-between center" fxLayoutGap="gappx"
              fxLayout.xs="column" fxLayoutAlign.xs="space-around strech">
              <mat-form-field class="example-full-width" fxFlex="47">
                <mat-icon matPrefix class="mr-12 s-20 secondary-text">account_circle</mat-icon>
                <input matInput placeholder="{{'Name'|translate}}" min="0" [formControl]="form.controls['nombre']"
                  required>
                <mat-error *ngIf="form.controls['nombre'].hasError('required')"> this field is<strong>
                    required</strong></mat-error>
              </mat-form-field>

              <mat-form-field class="example-full-width" fxFlex="47">
                <mat-icon matPrefix class="mr-12 s-20 secondary-text">account_circle</mat-icon>
                <input matInput placeholder="{{'Last Name'|translate}}" min="0" [formControl]="form.controls['apellidos']"
                  required>
                <mat-error *ngIf="form.controls['apellidos'].hasError('required')"> this field is<strong>
                    required</strong></mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="gappx">
              <mat-form-field class="example-full-width" fxFlex="100">
                <mat-icon matPrefix class="mr-12 s-20 secondary-text">assignment_ind</mat-icon>
                <input matInput type="number" placeholder="{{'DNI'|translate}}" min="0" required [formControl]="form.controls['identidad']">
                <mat-error *ngIf="form.controls['identidad'].hasError('required')"> this field is<strong>
                    required</strong></mat-error>
                <mat-error *ngIf="form.controls['identidad'].hasError('min')||form.controls['identidad'].hasError('max')">
                  Enter a valid DNI
                </mat-error>
              </mat-form-field>
            </div>



            <div fxLayout="row" class="cf-margin-top-10" fxLayoutAlign="space-between center" fxLayoutGap="gappx">
              <!-- <mat-form-field class="example-full-width" fxFlex="47">
                <mat-icon matPrefix class="mr-12 s-20 secondary-text">location_city</mat-icon>
                <input matInput placeholder="{{'Country'|translate}}" [formControl]="form.controls['pais']" required>
                <mat-error *ngIf="form.controls['pais'].hasError('required')"> this field is<strong>
                    required</strong></mat-error>
              </mat-form-field> -->

              <mat-form-field class="example-full-width" fxFlex="47">
                <mat-select formControlName="pais" required placeholder="{{'Country'|translate}}">
                  <mat-icon matPrefix class="mr-12 s-20 secondary-text">location_city</mat-icon>
                  <mat-option *ngFor="let pais of paises" [value]="pais">{{pais}}</mat-option>
                </mat-select>
                <mat-error *ngIf="form.controls['pais'].hasError('required')"> this field is<strong>
                    required</strong></mat-error>
              </mat-form-field>


              <mat-form-field class="example-full-width" fxFlex="47">
                <mat-icon matPrefix class="mr-12 s-20 secondary-text">location_city</mat-icon>
                <input type="text" placeholder="{{'City'|translate}}" aria-label="Assignee" matInput [formControl]="form.controls['departamento']"
                  [matAutocomplete]="auto">
                <mat-error *ngIf="form.controls['departamento'].hasError('required')"> this field is<strong>
                    required</strong></mat-error>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                  <mat-option *ngFor="let provincia of filteredProvincias | async" [value]="provincia.name">
                    {{provincia.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <!-- <mat-form-field class="example-full-width" fxFlex="47">
                <mat-icon matPrefix class="mr-12 s-20 secondary-text">location_city</mat-icon>
                <input matInput placeholder="{{'City'|translate}}" [formControl]="form.controls['departamento']"
                  required>
                <mat-error *ngIf="form.controls['departamento'].hasError('required')"> this field is<strong>
                    required</strong></mat-error>
              </mat-form-field> -->

            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="gappx" fxLayout.xs="column"
              fxLayoutAlign.xs="space-around strech">

              <!-- <mat-form-field class="example-full-width" fxFlex="47">
                <mat-icon matPrefix class="mr-12 s-20 secondary-text">location_on</mat-icon>
                <input matInput placeholder="{{'Location'|translate}}" [formControl]="form.controls['localidad']">
              </mat-form-field> -->

              <mat-form-field class="example-full-width" fxFlex="47">
                <mat-icon matPrefix class="mr-12 s-20 secondary-text">location_on</mat-icon>
                <input type="text" placeholder="{{'Location'|translate}}" aria-label="Assignee" matInput [formControl]="form.controls['localidad']"
                  [matAutocomplete]="auto2">
                <mat-error *ngIf="form.controls['localidad'].hasError('required') || form.controls['localidad'].hasError('minlength')">
                  this field is<strong>
                    required</strong></mat-error>

                <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayMunicipios">
                  <mat-option *ngFor="let municipio of filteredMunicipios | async" [value]="municipio.name">
                    {{municipio.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>


              <mat-form-field class="example-full-width" fxFlex="47">
                <mat-icon matPrefix class="mr-12 s-20 secondary-text">library_books</mat-icon>
                <input matInput placeholder="{{'Address'|translate}}" [formControl]="form.controls['direccion']"
                  required>
                <mat-error *ngIf="form.controls['direccion'].hasError('required')"> this field is<strong>
                    required</strong></mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" class="cf-margin-top-10" fxLayoutAlign="space-between center" fxLayoutGap="gappx"
              fxLayout.xs="column" fxLayoutAlign.xs="space-around strech">
              <mat-form-field class="example-full-width" fxFlex="100">
                <mat-icon matPrefix class="mr-12 s-20 secondary-text">cake</mat-icon>
                <input matInput [matDatepicker]="picker" placeholder="{{'Birthdate'|translate}}" [formControl]="form.controls['fecha_nacimiento']"
                  required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="form.controls['fecha_nacimiento'].hasError('required')"> this field is<strong>
                    required</strong></mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="gappx">
              <mat-form-field class="example-full-width" fxFlex="47">
                <mat-icon matPrefix class="mr-12 s-20 secondary-text">phone</mat-icon>
                <input matInput placeholder="{{'Phone'|translate}}" [formControl]="form.controls['telefono']">
              </mat-form-field>

              <mat-form-field class="example-full-width" fxFlex="47">
                <mat-icon matPrefix class="mr-12 s-20 secondary-text">phone_iphone</mat-icon>
                <input matInput placeholder="{{'Movil'|translate}}" [formControl]="form.controls['movil']">
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="gappx">
              <mat-form-field fxFlex="100" class="example-full-width">
                <mat-icon matPrefix class="mr-12 s-20 secondary-text">email</mat-icon>
                <input matInput placeholder="{{'Email'|translate}}" formControlName="email">
                <mat-error *ngIf="form.controls['email'].hasError('email')">
                  You most enter a valid email
                </mat-error>
              </mat-form-field>

            </div>

          </div>
        </form>
      </mat-step>
      <mat-step *ngIf="!isOnlyBasicInfo" [editable]="form.valid">
        <ng-template matStepLabel>{{'Asignar Cargos'|translate}}</ng-template>
        <div *ngIf="DepartamentosCargosReady" fxLayout="column" fxLayoutAlign="space-around stretch">
          <div id="cabecera" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
            <span><strong>Listado de Departamentos</strong></span>
          </div>
          <div style="margin-top:10px;" *ngFor="let departamento of allDepartments" fxLayout="row" fxLayoutAlign="start start"
            fxFlex="100" fxLayoutGap="gappx">
            <mat-accordion fxFlex="100" displayMode="default" [multi]="true" [hideToggle]="false">
              <mat-expansion-panel [hideToggle]="false">
                <mat-expansion-panel-header>
                  <span *ngIf="DepartamentosCargos[departamento.id].length > 0" id="departamentoNombre"><strong>{{departamento.nombre}}
                      <mat-icon class="mat-18">check</mat-icon> </strong></span>
                  <span *ngIf="DepartamentosCargos[departamento.id].length == 0" id="departamentoNombre">{{departamento.nombre}}</span>
                </mat-expansion-panel-header>
                <div id="listado-cargos" *ngFor="let cargo of DepartamentosCargos[departamento.id]" fxLayout="row"
                  fxLayoutAlign="start start" fxLayoutGap="gappx">
                  <span (click)="onClickCargo(cargo)" id="listado-cargos-span">{{cargo.titulo}}</span>

                </div>
                <mat-action-row>
                  <button mat-button (click)="onAddCargo(departamento)">
                    <mat-icon class="mat-18">add</mat-icon>
                  </button>
                  <button mat-button (click)="onDeleteCargo(departamento)">
                    <mat-icon class="mat-18">delete</mat-icon>
                  </button>
                </mat-action-row>
              </mat-expansion-panel>
            </mat-accordion>

          </div>

        </div>

      </mat-step>
    </mat-horizontal-stepper>



  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end end">
    <button mat-raised-button (click)="onCreate()" [disabled]="form.invalid" class="save-button mat-accent" aria-label="SAVE">
      {{'Save'|translate}}
    </button>
  </div>

</div>
