import { Injectable }      from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable()
export class ErrorMessagesService {

  constructor() {
  }

  getSingleErrorMessage(control: AbstractControl): string {
    let message = '';

    // TODO (i18n): Add i18n to the following strings when support gets added to Angular.
    if (control.hasError('required')) {
      message = 'You must enter a value.';
    }
    else if (control.hasError('email')) {
      message = 'Not a valid email address.';
    }
    else if (control.hasError('matchOther')) {
      const error = control.getError('matchOther');
      message     = `The field has to match with '${error.otherField}'.`;
    }
    else if (control.hasError('minlength')) {
      const error = control.getError('minlength');
      message     = `The field required length is ${error.requiredLength} and actual length is ${error.actualLength}.`;
    }
    else if (control.hasError('maxlength')) {
      const error = control.getError('maxlength');
      message     = `The field required length is ${error.requiredLength} and actual length is ${error.actualLength}.`;
    }

    return message;
  }
}
