import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTreeModule } from '@angular/material/tree';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FuseConfirmDialogModule, FuseSidebarModule } from '../../../@fuse/components';
import { FuseSharedModule } from '../../../@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { DialogUserAddEditComponent } from './dialog-user-add-edit/dialog-user-add-edit.component';
import { ImagesModule } from '../images/images.module';
import { HorarioSemanalComponent } from './dialog-add-cargo/horario-semanal/horario-semanal.component';
import { HorarioOneWeekComponent } from './dialog-add-cargo/horario-semanal/horario-one-week/horario-one-week.component';
import { HorarioOneMesType1Component } from './dialog-add-cargo/horario-mensual/horario-one-mes-type1/horario-one-mes-type1.component';
import { HorarioOneMesType2Component } from './dialog-add-cargo/horario-mensual/horario-one-mes-type2/horario-one-mes-type2.component';
import { HorarioMensualComponent } from './dialog-add-cargo/horario-mensual/horario-mensual.component';
import { HorarioAlternoComponent } from './dialog-add-cargo/horario-alterno/horario-alterno.component';
import { DialogAddCargoComponent } from './dialog-add-cargo/dialog-add-cargo.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DialogListCargosComponent } from './dialog-list-cargos/dialog-list-cargos.component';
import { DialogListPersonToCargoComponent } from './dialog-list-person-to-cargo/dialog-list-person-to-cargo.component';
import { DialogQuestionarioPersonaComponent } from './dialog-questionario-persona/dialog-questionario-persona.component';
import { DialogAddEditPersonComponent } from './dialog-add-edit-person/dialog-add-edit-person.component';

import * as moment from 'moment';

@NgModule({
    imports: [
        FuseSharedModule,
        FuseConfirmDialogModule,
        FuseSidebarModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatSelectModule,
        MatAutocompleteModule,
        ImagesModule,
        TranslateModule,
        MatChipsModule, MatPaginatorModule, MatSnackBarModule,
        MatListModule,
        MatRadioModule,
        MatSortModule,
        MatTabsModule,
        MatStepperModule,
        MatExpansionModule,
        MatTreeModule,
        MatSlideToggleModule,
        MatCardModule,
        NgxMaterialTimepickerModule.forRoot()
    ],
    declarations: [
        DialogUserAddEditComponent, DialogAddEditPersonComponent, HorarioSemanalComponent, HorarioOneWeekComponent,
        HorarioOneMesType1Component, HorarioOneMesType2Component, HorarioMensualComponent, HorarioAlternoComponent,
        DialogAddCargoComponent, DialogListCargosComponent, DialogListPersonToCargoComponent,
        DialogQuestionarioPersonaComponent
    ]
})
export class SharedModule {
}
