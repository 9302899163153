<form [formGroup]="form">
  <div style="margin-top:10px;" fxLayout="row" fxLayoutAlign.xs="start start" fxLayoutAlign="start start">
    <span id="Los-dias" fxFlex="5"><strong>El:</strong></span>
    <mat-form-field id="IterDias" fxFlex=13 fxFlex.xs=19>
      <mat-select formControlName="indexMes" placeholder="index" required>
        <mat-option *ngFor="let index of Indexs" [value]="index">
          {{index}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field id="selectDiaSemana" fxFlex=25>
      <mat-select formControlName="dia" placeholder="Dia" required>
        <mat-option *ngFor="let dia of Semana" [value]="dia">
          {{dia}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <span id="label-in" fxFlex="5"><strong>ent:</strong></span>
    <div id="HorarioEntrada" fxFlex="18" fxFlex.xs="13">
      <input class="min-time-example" required formControlName="entrada" matInput placeholder="{{'pick'|translate}}"
        [ngxTimepicker]="min">
      <ngx-material-timepicker #min></ngx-material-timepicker>
    </div>
    <span id="label-out" fxFlex="5"><strong>sal:</strong></span>
    <div id="HorarioSalida" fxFlex="18" fxFlex.xs="13">
      <input class="max-time-example" required formControlName="salida" matInput placeholder="{{'pick'|translate}}"
        [ngxTimepicker]="max">
      <ngx-material-timepicker #max></ngx-material-timepicker>
    </div>
  </div>
</form>
