import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoggedInUserService {
  readonly loggedInUserUpdated$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  loggedInUser: any;

  constructor() {
    const data = localStorage.getItem('user');
    this.loggedInUser = JSON.parse(data);
  }

  public setNewAvatar(user): any {
    let dataString: string;
    // this.loggedInUser.avatar = user.avatar;
    dataString = JSON.stringify(this.loggedInUser);
    localStorage.setItem('user', dataString);
    this.loggedInUserUpdated$.next(this.loggedInUser);
  }

  public getLoggedInUser(): any {
    return this.loggedInUser;
  }

  public setLoggedInUser(user: any): any {
    this.loggedInUser = JSON.parse(JSON.stringify(user));
  }

  public updateUserProfile(user): any {
    let dataString: string;
    // this.loggedInUser.avatar = user.avatar;
    this.loggedInUser.persona.image = user.persona.image;
    this.loggedInUser.persona.nombre = user.persona.nombre;
    this.loggedInUser.persona.apellidos = user.persona.apellidos;
    // this.loggedInUser.cellphone = user.cellphone;
    this.loggedInUser.username = user.username;
    // this.loggedInUser.address = user.address;
    dataString = JSON.stringify(this.loggedInUser);
    localStorage.setItem('user', dataString);
    this.loggedInUserUpdated$.next(this.loggedInUser);
  }

}
