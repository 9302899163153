import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LoggedInUserService } from '../core/loggedInUser/logged-in-user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router,
    private loggedInUserService: LoggedInUserService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAccess();
  }

  checkAccess(): boolean {
    if (this.loggedInUserService.getLoggedInUser()) {
      if (this.loggedInUserService.getLoggedInUser().role === 'superadmin' || this.loggedInUserService.getLoggedInUser().role === 'admin') {
        this.router.navigate(['backend/department']);
      } else if (this.loggedInUserService.getLoggedInUser().role === 'messenger') {
        this.router.navigate(['backend/sample']);
      } else {
        this.router.navigate(['backend/department']);
      }

      return true;
    }

    return true;
  }
}
