import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Observable, Subject, fromEvent } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { buildMapFromSet } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { DepartmentService } from '../../../backend/department/services/department.service';
import { group } from '@angular/animations';
import { Cargo } from '../../../backend/department/clases/cargo.class';
import { fuseAnimations } from '@fuse/animations';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Persona } from '../../../backend/personal/clases/persona.class';
import { SelectionModel } from '@angular/cdk/collections';
import { environment } from '../../../../environments/environment';
import { PersonalService } from 'app/backend/personal/services/personal.service';
import { DialogAddCargoComponent } from '../dialog-add-cargo/dialog-add-cargo.component';


@Component({
  selector: 'app-dialog-list-cargos',
  templateUrl: './dialog-list-cargos.component.html',
  styleUrls: ['./dialog-list-cargos.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class DialogListCargosComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  selectedallCargos: Boolean = false;
  selectedCargos: any = {};
  allCargos: Cargo[]; // [{ name: 'Jefe', id: '0' }, { name: 'Secretario', id: '1' }, { name: 'Programador', id: '3365123okjjhdfhgfgbg' }];
  stepCargosComplete: Boolean = false;
  cargos: Cargo[] = [];
  newcargosSelected: Cargo[] = [];
  @ViewChild('FilterCargos')
  FilterCargos: ElementRef;

  constructor(public dialogRef: MatDialogRef<DialogListCargosComponent>,
    @Inject(MAT_DIALOG_DATA) private dataDialog: any,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private departmentService: DepartmentService) {
    this.allCargos = this.dataDialog.cargos;
    this.cargos = this.dataDialog.cargos;
    this._unsubscribeAll = new Subject();

  }

  ngOnInit(): void {
    console.log(this.cargos);
    fromEvent(this.FilterCargos.nativeElement, 'keyup')
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe(() => {
        if (!this.cargos) {
          return;
        }
        this.cargos = this.filterCargos(this.FilterCargos.nativeElement.value);
      });
    //////////////////////// subcripciones////////
    this.departmentService.newcargo.subscribe(data => {
      let found = false;
      for (let i = 0; i < this.cargos.length; i++) {
        if (this.cargos[i].id === data.id) {
          this.cargos[i] = data;
          found = true;
          break;
        }
      }
      if (!found) {
        this.cargos.push(data);
      }

    });


  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

  }
  //////////// Cargos Interface/////////////////////
  onCreateCargo(editCargo: boolean, cargo: Cargo): void {
    // console.log(cargo);
    if (!editCargo) {
      const dialogRef = this.dialog.open(DialogAddCargoComponent, {
        panelClass: 'app-dialog-add-cargo',
        maxWidth: '100vw',
        maxHeight: '100vh',
        data: {
          isEditing: false,
          selectedCargo: cargo
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
        }
      });
    }
    else {
      const dialogRef = this.dialog.open(DialogAddCargoComponent, {
        panelClass: 'app-dialog-add-cargo',
        maxWidth: '100vw',
        maxHeight: '100vh',
        data: {
          isEditing: true,
          selectedCargo: cargo
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
        }
      });
    }

  }

  onSelectAllCargos(fromChecked?): void {
    let count = 0;
    if (!fromChecked) {
      this.selectedallCargos = !this.selectedallCargos;
    }
    this.cargos.forEach(item => {
      this.selectedCargos[item.id] = this.selectedallCargos ? true : false;
    });
    this.newcargosSelected = [];
    for (const item in this.selectedCargos) {
      if (this.selectedCargos[item]) {
        for (let i = 0; i < this.cargos.length; i++) {
          if (this.cargos[i].id === item) {
            this.newcargosSelected.push(this.cargos[i]);
          }
        }
        count++;
      }
    }
    // this.cargos2Bind = temp_cargos;

    if (count > 0) {
      this.stepCargosComplete = true;
    } else {
      this.stepCargosComplete = false;
    }
  }
  onSelectCargo(cargo, flag): void {
    // console.log(cargo);
    let count = 0;
    if (flag) {
      if (this.selectedCargos[cargo.id]) {
        this.selectedCargos[cargo.id] = false;
      } else {
        this.selectedCargos[cargo.id] = true;
      }
    }
    this.newcargosSelected = [];
    // console.log(this.selectedCargos);
    for (const item in this.selectedCargos) {
      if (this.selectedCargos[item]) {
        for (let i = 0; i < this.allCargos.length; i++) {
          if (this.allCargos[i].id === item) {
            this.newcargosSelected.push(this.allCargos[i]);
          }
        }
        count++;
      }
    }
    // this.cargos2Bind = temp_cargos;

    if (count > 0) {
      this.stepCargosComplete = true;
    } else {
      this.stepCargosComplete = false;
    }
    // console.log(temp_cargos);

  }
  matchStr(cadena: string, target: string): boolean {
    if (target.length > cadena.length) {
      return false;
    }
    let finish = false;
    for (let i = 0; i <= cadena.length - target.length && !finish; i++) {
      if (cadena.substring(i, i + target.length) === target) {
        finish = true;
        break;
      }
    }
    return finish;
  }

  filterCargos(target: string): Cargo[] {
    const newCargosList: Cargo[] = [];
    for (let i = 0; i < this.allCargos.length; i++) {
      const item = this.allCargos[i];
      if (this.matchStr(item.titulo.toLowerCase(), target.toLowerCase())) {
        newCargosList.push(item);
      }
    }
    newCargosList.sort((obj1, obj2) => {
      if (obj1.titulo.toUpperCase() > obj2.titulo.toUpperCase()) {
        return 1;
      }
      if (obj1.titulo.toUpperCase() < obj2.titulo.toUpperCase()) {
        return -1;
      }
      else {
        return 0;
      }
    });
    return newCargosList;
  }


}
